<template>
  <div class="grantCards">
    <h1 class="help__message" v-if="accessEdit">
      <span v-if="value.grantType === 'personal'">Блок карточек стипендий (именные)</span>
      <span v-if="value.grantType === 'government'">Блок карточек стипендий (правительства)</span>
      <span v-if="value.grantType === 'foreign'">Блок карточек стипендий (зарубежные)</span>
    </h1>
    <grant-card
      v-for="(item, i) in value.cards"
      :key="i"
      :access-edit="accessEdit"
      :value.sync="item"
      v-bind:grantType="value.grantType"
      @add-card="addCard()"
      @add-card-first="addCardFirst()"
      @delete-card="deleteCard(i)"
      @change-prev="changeCardPositionPrev(i)"
      @change-next="changeCardPositionNext(i)"
    />
  </div>
</template>

<script>
import GrantCard from "@/components/Main/admin/GrantCard";

export default {
  name: "grantCards",
  components: {GrantCard},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    },
  },
  data() {
    return {
      edit: false,
    }
  },
  methods: {
    addCard() {
      if (this.value.grantType === 'personal') {
        const newItem = {
          id: 0,
          grantLink: '',
          backgroundImage: '',
          headerText: 'Персональная стипендия имени',
          subjectText: 'Предмет',
          type: 'Студентам очной формы обучения'
        }
        this.value.cards.push(newItem)
      }
      if ((this.value.grantType === 'government') || (this.value.grantType === 'foreign')) {
        const newItem = {
          id: 0,
          grantLink: '',
          backgroundImage: '',
          headerText: '',
          type: ''
        }
        this.value.cards.push(newItem)
      }
    },
    addCardFirst() {
      if (this.value.grantType === 'personal') {
        const newItem = {
          id: 0,
          grantLink: '',
          backgroundImage: '',
          headerText: 'Персональная стипендия имени',
          subjectText: 'Предмет',
          type: 'Студентам очной формы обучения'
        }
        this.value.cards.unshift(newItem)
      }
      if ((this.value.grantType === 'government') || (this.value.grantType === 'foreign')) {
        const newItem = {
          id: 0,
          grantLink: '',
          backgroundImage: '',
          headerText: '',
          type: ''
        }
        this.value.cards.unshift(newItem)
      }
    },
    deleteCard(index) {
      this.value.cards.splice(index, 1)
    },
    changeCardPositionPrev(index) {
      if (index > 0) {
        this.value.cards.splice(index - 1, 2, this.value.cards[index], this.value.cards[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeCardPositionNext(index) {
      if (index + 1 !== this.value.cards.length) {
        this.value.cards.splice(index, 2, this.value.cards[index + 1], this.value.cards[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  }
}
</script>

<style scoped>
</style>