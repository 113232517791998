<template>
<!--  <div-->
<!--      v-if="(((value.year === $attrs.year) || ($attrs.year === 'Все года')) &&-->
<!--    ((value.documentType === $attrs.documentType) || ($attrs.documentType === 'Все документы')) &&-->
<!--    ((value.grantType === $attrs.grantType) || ($attrs.grantType === 'Все стипендии'))) || newDocumentType"-->
<!--      @mouseout="linkHover = false"-->
<!--      @mouseover="linkHover = true"-->
<!--  >-->
    <div>
        <div class='document__link' @click="openDocument(value.fileLink)">
          <img v-if="!linkHover" alt="" class="document__icon" src="@/assets/icons/pdf.svg">
          <img v-if="linkHover" alt="" class="document__icon" src="@/assets/icons/pdf-hover.svg">
          <p class="document__title" v-html="value.title"></p>
        </div>
<!--        <a :href="linkFormat(value.fileLink)" class="document__link" target="_blank" v-else>-->
<!--          <img v-if="!linkHover" alt="" class="document__icon" src="@/assets/icons/pdf.svg">-->
<!--          <img v-if="linkHover" alt="" class="document__icon" src="@/assets/icons/pdf-hover.svg">-->
<!--          <p class="document__title" v-html="value.title"></p>-->
<!--        </a>-->
<!--    <a :href="linkFormat" class="document__link" target="_blank" download>-->
<!--      <img v-if="!linkHover" alt="" class="document__icon" src="@/assets/icons/pdf.svg">-->
<!--      <img v-if="linkHover" alt="" class="document__icon" src="@/assets/icons/pdf-hover.svg">-->
<!--      <p class="document__title" v-html="value.title"></p>-->
<!--    </a>-->
<!--    <div v-if="edit" class="documents-edit">-->
<!--      <div>-->
<!--        <label>Заголовок:</label>-->
<!--        <vue-editor :edit-data-prop.sync="value.title"/>-->
<!--      </div>-->
<!--      <div>-->
<!--        <label>Адрес ссылки:</label>-->
<!--        <input v-model="value.fileLink" style="width: 500px; border: 1px solid grey; margin-left: 20px;">-->
<!--      </div>-->
<!--      <div>-->
<!--        <label>Тип гранта (страница документов):</label>-->
<!--        <input v-model="value.grantType" style="border: 1px solid grey; margin-left: 20px;">-->
<!--      </div>-->
<!--      <div>-->
<!--        <label>Тип документа (страница документов):</label>-->
<!--        <input v-model="value.documentType" style="border: 1px solid grey; margin-left: 20px;">-->
<!--      </div>-->
<!--      <div>-->
<!--        <label>Год (страница документов):</label>-->
<!--        <input v-model="value.year" style="border: 1px solid grey; margin-left: 20px;">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-if="accessEdit" class="system-buttons">-->
<!--      <v-button @click="addItemFirst(value)">+ первым</v-button>-->
<!--      <v-button class="ml-2" @click="addItem(value)">+ последним</v-button>-->
<!--      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>-->
<!--      <v-button class="ml-2" @click="deleteItem(value)">Delete</v-button>-->
<!--      <v-button class="ml-2" @click="changeDocPositionPrev(value)">&#8592;</v-button>-->
<!--      <v-button class="ml-2" @click="changeDocPositionNext(value)">&#8594;</v-button>-->
<!--    </div>-->
  </div>
</template>

<script>
//import VButton from "@/components/UI/vButton";
//import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "Document",
  components: {
    //VButton,
    //VueEditor
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    newDocumentType: {
      type: Boolean,
      default: false
    },
    isNews: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
      linkHover: false,
    }
  },
  computed: {
    linkFormat() {
      return `https://files.ined.ru/adminpanel/${this.value.fileLink}.pdf`
      // return `https://files.ined.ru/adminpanel/${5776_345_813aa2f6a87b581bb9bba71000d29f07}.pdf`
      // if (link.substring(0, 4) === 'http') {
      //   return link
      // }
      // if (link.substring(0, 4) === '/htt') {
      //   return link.substring(1)
      // }
      //
      // return `http://${link}`
    }
  },
  methods: {
    openDocument(fileLink) {
      let url = ''
      if (location.origin.includes('localhost')) {
        url = 'https://srf.test.ined.ru'
      } else {
        url = location.origin
      }
      if (this.isNews) {
        url = url + `/api/news/documents/${fileLink}`
      } else {
        url = url + `/api/documents/${fileLink}`
      }
      const link = document.createElement('a');
      link.href = url;
      link.download = fileLink;
      link.click();
      link.remove();
    },
    deleteItem(item) {
      this.$emit('delete-document', item)
    },
    addItem(item) {
      this.$emit('add-document', item)
    },
    addItemFirst(item) {
      this.$emit('add-document-first', item)
    },
    changeDocPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeDocPositionNext(item) {
      this.$emit('change-next', item)
    },

  },
}
</script>

<style scoped>

</style>