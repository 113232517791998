<template>
  <div style="display:flex;">
    <div :class="($attrs.grantType === 'Именная') ? 'grantAchievement-personal' : 'grantAchievement'">
      <div v-if="!edit" style="display: flex; flex-direction: column">
        <p class="grantAchievement__text" v-html="value.text"></p>
      </div>
      <div v-if="edit">
        <div style="margin-top: 10px; width: 500px">
          <span>Текст:</span>
          <vue-editor :edit-data-prop.sync="value.text"/>
        </div>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0">
      <v-button @click="addItemFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addItem(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteItem(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeItemPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeItemPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "GrantAchievement",
  components: {VueEditor, VButton},
  props: {
    value: {
      type: [Object, Number],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-achievement', item)
    },
    addItem(item) {
      this.$emit('add-achievement', item)
    },
    addItemFirst(item) {
      this.$emit('add-achievement-first', item)
    },
    changeItemPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeItemPositionNext(item) {
      this.$emit('change-next', item)
    },
  }
}
</script>

<style scoped>

</style>