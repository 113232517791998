<template>
  <div>
    <div v-if="!edit" class="mainText">
      <img :src="value.image" alt="" class="mainText__image">
      <div class="mainText__p" v-html="value.text"></div>
    </div>
    <div v-if="edit">
      <h1 class="help__message">Цитата + фото/видео (для главной страницы)</h1>
      <div style="margin-top: 10px;">
        <label>Ссылка изображения:</label>
        <input v-model="value.image" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Текст:</label>
        <vue-editor :edit-data-prop.sync="value.text"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VueEditor from "@/components/UI/vue-ckeditor";
import VButton from "@/components/UI/vButton";

export default {
  name: "textComponent",
  components: {VButton, VueEditor},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false
    }
  },
}
</script>

<style scoped>

</style>