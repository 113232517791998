<template>
  <div class="contacts__delegates">
    <h1 v-if="accessEdit" class="help__message">Страница контактов</h1>
    <div v-if="!edit">
      <h3 class="delegates__header" v-html="value.header"></h3>
      <p class="delegates__text" v-html="value.text"></p>
      <div class="delegates__searchBlock">
        <input v-model="filterText" class="delegates__search" placeholder="Наименование организации">
        <button class="delegates__search-btn" @click="filter = filterText">Поиск</button>
      </div>
      <div v-if="filter" class="contacts__line"></div>
    </div>
    <div v-if="filter">
      <university
          v-for="(item) in filteredUniversities"
          :key="item.id"
          :access-edit="accessEdit"
          :value.sync="item"
          class="vvv"
      />
    </div>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <span class="newsItem__label">Заголовок:</span>
        <input v-model="value.header" style="border: 1px solid grey; margin-left: 20px;  width: 500px"/>
      </div>
      <div style="margin-top: 10px;">
        <span class="newsItem__label">Пояснительный текст:</span>
        <input v-model="value.text" style="border: 1px solid grey; margin-left: 20px;  width: 500px"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import University from "@/components/Main/admin/contacts/University";
import {getAllUniversities} from "@/API/Admin";
import VButton from "@/components/UI/vButton";

export default {
  name: "Delegates",
  components: {University, VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      filter: '',
      filterText: '',
      allUniversities: [],
    }
  },
  mounted() {
    this.getAllUniversities();
  },
  computed: {
    filteredUniversities() {
      let filteredList = [...this.allUniversities]
      let search = this.filter.toLowerCase()
      if (search) {
        filteredList = filteredList.filter(item => {
          return item['short_name'].toLowerCase().indexOf(search) !== -1 || item.university.toLowerCase().indexOf(search) !== -1
        })
      }
      return filteredList
    }
  },
  methods: {
    async getAllUniversities() {
      let res = await getAllUniversities();
      this.allUniversities = res.data;
      // if (res.status === 200) {
      //   console.log('Успех');
      // } else {
      //   console.log('Неудача')
      // }
    },
  },

}
</script>

<style scoped>

</style>