<template>
  <div class="stepsCarouselBlock">
    <div v-if="!edit" class="stepsCarouselDiv">
      <h3 class="stepsCarousel__header" v-html="value.title"></h3>
      <p class="stepsCarousel__subtitle" v-html="value.subtitle"></p>
    </div>
    <v-carousel v-model="model">
      <template v-slot:prev="{ on, attrs }">
        <img v-if="!edit" src="@/assets/icons/prev-step.svg" v-bind="attrs" v-on="on" alt="">
      </template>
      <template v-slot:next="{ on, attrs }">
        <img v-if="!edit" src="@/assets/icons/next-step.svg" v-bind="attrs" v-on="on" alt="">
      </template>
      <v-carousel-item
        v-for="(item, i) in value.carouselItems"
        :key="i"
      >
        <v-sheet tile>
          <v-row
            align="center"
            class="fill-height"
            justify="center"
          >
            <div v-if="edit" style="color:white;">
              <h1 class="help__message">карусель шагов</h1>
              <div style="margin-top: 10px;">
                <label>Заголовок:</label>
                <input v-model="value.title" style="margin-left: 20px; border: 1px solid grey; width: 500px; color: white">
              </div>
              <div style="margin-top: 10px;">
                <label>Подзаголовок:</label>
                <input v-model="value.subtitle" style="margin-left: 20px; border: 1px solid grey; width: 500px; color: white">
              </div>
              <div style="margin-top: 10px;">
                <label>Цифра:</label>
                <input v-model="item.number" style="margin-left: 20px; border: 1px solid grey; width: 500px; color: white">
              </div>
              <div style="margin-top: 10px;">
                <label>Текст:</label>
                <vue-editor style="color: black" :edit-data-prop.sync="item.text"/>
              </div>
              <div style="margin-top: 10px;">
                <label>Название кнопки:</label>
                <input v-model="item.btnName" style="margin-left: 20px; border: 1px solid grey; width: 500px; color: white">
              </div>
              <div style="margin-top: 10px;">
                <label>Ссылка кнопки:</label>
                <input v-model="item.btnLink" style="margin-left: 20px; border: 1px solid grey; width: 500px; color: white">
              </div>
              <div v-if="accessEdit" style="position: absolute; bottom: 0; right: 0">
                <v-button @click="changeCarouselItemPositionPrev(i)">&#8592;</v-button>
                <v-button class="ml-2" @click="changeCarouselItemPositionNext(i)">&#8594;</v-button>
                <v-button class="ml-2" @click="deleteCarouselItem(i)">Delete</v-button>
              </div>
            </div>
            <div v-if="!edit" class="stepsCarousel">
              <div class="stepsCarousel__textBlock">
                <div class="stepsCarousel__number-bg">
                  <span class="stepsCarousel__number" v-html="item.number"></span>
                </div>
                <p class="stepsCarousel__text" v-html="item.text"></p>
              </div>
              <a v-if="item.btnName" :href="item.btnLink" class="carousel__btn" v-html="item.btnName"></a>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="addCarouselItemFirst()">+ первым</v-button>
      <v-button class="ml-2" @click="addCarouselItem()">+ последним</v-button>
      <v-button class="ml-2" @click="edit =! edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "stepsCarousel",
  components: {
    VueEditor,
    VButton,
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      model: 0,
    }
  },
  methods: {
    addCarouselItem() {
      const newItem = {
        id: 0,
        text: '',
        number: '',
        btnName: 'Подробнее',
        btnLink: '/'
      }
      this.value.carouselItems.push(newItem)
    },
    addCarouselItemFirst() {
      const newItem = {
        id: 0,
        text: '',
        number: '',
        btnName: 'Подробнее',
        btnLink: '/'
      }
      this.value.carouselItems.unshift(newItem)
    },
    deleteCarouselItem(index) {
      this.value.carouselItems.splice(index, 1)
    },
    changeCarouselItemPositionPrev(index) {
      if (index > 0) {
        this.value.carouselItems.splice(index - 1, 2, this.value.carouselItems[index], this.value.carouselItems[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeCarouselItemPositionNext(index) {
      if (index + 1 !== this.value.carouselItems.length) {
        this.value.carouselItems.splice(index, 2, this.value.carouselItems[index + 1], this.value.carouselItems[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  }
}

</script>

<style lang="scss" scoped>
.help__message{
  background-color: white;
  width: 250px;
  color: black;
}
</style>