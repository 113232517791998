<template>
  <div class="mediaVideoBlock" style="border-bottom: 1px solid #7492A0">
    <h1 class="help__message" v-if="accessEdit">Видео (медиа)</h1>
    <div v-if="!edit">
      <h3 class="mediaVideoBlock__text" v-html="value.text"></h3>
      <video :src="value.link" class="mediaVideoBlock__video" controls="controls"/>
      <div class="mediaVideoBlock__dateDiv">
        <img alt="" class="mediaVideoBlock__icon" src="@/assets/icons/calendar.svg">
        <p class="mediaVideoBlock__date" v-html="value.date"></p>
      </div>
    </div>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <label>Заголовок:</label>
        <input v-model="value.text" style="width: 500px; border: 1px solid grey; margin-left: 20px;">
      </div>
      <div>
        <label>Адрес ссылки:</label>
        <input v-model="value.link" style="width: 500px; border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Дата:</label>
        <input v-model="value.date" style="width: 500px; border: 1px solid grey; margin-left: 20px;">
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0">
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton"

export default {
  name: "mediaVideo",
  components: {VButton},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
    }
  },
}
</script>

<style scoped>

</style>