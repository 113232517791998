<template>
  <div style="margin-bottom: 20px;">
    <p class="stipName">{{ value.name }}</p>
    <div
        class="card-container"
    >
      <div class="card-left-container">
        <a v-if="value.npa !==null" :href="`${value.npa}`">
          <h3 class="card-left-container__title">{{ value.name }}</h3>
        </a>
        <h3 class="card-left-container__title" v-else>{{ value.name }}</h3>
        <span class="card-left-container__regionname">{{ value.orgFounderName }}</span>
      </div>
      <div class="card-right-container">
        <span class="card-right-container__title">Кому выплачивается</span>
        <span class="card-right-container__text" v-html="value.forWhos"></span>

        <div class="card-right-container-file">
          <a class="card-right-container__filename" :href="`${value.url}`">
            <img alt="pic" src="@/assets/icons/ch.svg" class="pdf-img">
            <span class="card-right-container__filename">Подробнее</span>
          </a>
          <a class="card-right-container__filename" v-if="value.npa !==null" :href="`${value.npa}`">
            <img alt="pdf" src="@/assets/icons/pdf.svg" class="pdf-img">
            <span class="card-right-container__filename">Нормативно-правовой акт</span>
          </a>
        </div>

        <span class="payments-title" v-if="nameValues">Размер выплаты</span>
          <div style="display: inline-block">
            <div class="payments-main-container"  v-for="(values,index) in nameValues" :key="index">
              <div class="payments-container" v-if="values !==null">
                <span class="payments__title">{{ values.header }}</span>
                <span class="payments__text">{{ values.sum }}</span>
                <span class="payments__title-value">{{ values.period }}</span>
                <span class="payments__title" v-if="values.count !== ''">Количество стипендий: {{ values.count }}</span>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "grantStipsCards",
  props: {
    value: {
      type: [Object]
    },
  },
  computed: {
    nameValues() {
      return JSON.parse(this.value.values)
    }
  }

}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/components/grantNamedRegionsCards";
.card-left-container__title{
  margin-bottom: 0;
  padding-bottom: 26px;

}
.card-right-container {
  justify-content: start;

  &__title {
    padding-bottom: 15px;

    @media screen and (max-width: 450px){
      padding: 7px;
    }
  }

  &__text {
    padding-bottom: 18px;
    @media screen and (max-width: 450px){
      padding: 8px;
    }
  }
}
.payments-title {
  padding-bottom: 24px;
  @media screen and (max-width: 450px){
    padding: 10px;
  }
}

.payments-main-container {
  display: inline-block;
  margin-right: 21px;
  margin-bottom: 20px;

  @media screen and (max-width: 450px){

  }
}

.payments-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width:500px;
  border-radius: 5px;
  border: 1px solid #B5C7D3;
  padding: 15px;

  @media screen and (max-width: 450px){
    min-width: 361px!important;

  }
}

.payments-main-container:last-child {
  margin-bottom: 0;
}


.stipName {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #0A5981;
  margin-bottom: 20px;

}
</style>
