<template>
  <div class="grantPage">
    <h1 v-if="accessEdit" class="help-message">виджет стипендии</h1>
    <GrantConditions
        :access-edit="accessEdit"
        :value.sync="value.grantConditions"
    />
    <GrantAmount
        :access-edit="accessEdit"
        :value.sync="value"
    />
    <GrantRequest
        :access-edit="accessEdit"
        :value.sync="value.grantRequest"
    />
    <div v-if="value.grantConditions.grantType === 'Именная'" class="winners">
      <p class="winners__header">Победители конкурса</p>
      <WinnersBlock
          :access-edit="accessEdit"
          :value.sync="value.winners"
      />
    </div>
    <div class="documents__files">
      <p class="documents__header">Нормативные документы</p>
      <v-btn v-if="accessEdit" @click="clearDocumentList" color="primary" width="200" style="margin: 15px auto">Удалить все документы</v-btn>
      <v-select
          v-if="accessEdit"
          return-object
          multiple
          v-model="value.documents"
          item-text="title"
          item-value="title"
          :items="allDocuments"
          label="Выберите документ"
          >
        <template slot="selection" slot-scope="{ item }">
          <span v-html="item.title" class="selected-item"></span>
        </template>
        <template slot="option" slot-scope="{ item }">
          <span v-html="item.title"></span>
        </template>
      </v-select>
<!--      <v-select-->
<!--          v-if="accessEdit"-->
<!--          :items="allDocuments"-->
<!--          v-model="documentsToAdd"-->
<!--          multiple-->
<!--          @change="getSelectedDocuments"-->
<!--      >-->
<!--        <template v-slot:selection="{ item, index }">-->
<!--          <v-chip v-html="item.title" :key="index"></v-chip>-->
<!--        </template>-->
<!--        <template v-slot:item="{ active, item, attrs, on }">-->
<!--          <v-list-item v-on="on" active="active"  :key="item.title">-->
<!--            <v-list-item-action>-->
<!--              <v-checkbox :input-value="active"></v-checkbox>-->
<!--            </v-list-item-action>-->
<!--            <v-list-item-content>-->
<!--              <v-list-item-title>-->
<!--                <v-row >-->
<!--                  <span v-html="item.title"></span>-->
<!--                </v-row>-->
<!--              </v-list-item-title>-->
<!--            </v-list-item-content>-->
<!--          </v-list-item>-->
<!--        </template>-->
<!--      </v-select>-->
      <Document
          v-for="(document, i) in value.documents"
          :key="i"
          :access-edit="accessEdit"
          :value.sync="document"
          class="document"
          @delete-document="deleteDocument(i)"
          @add-document="addDocument()"
          @add-document-first="addDocumentFirst()"
          @change-prev="changeDocPositionPrev(i)"
          @change-next="changeDocPositionNext(i)"
      />
    </div>
  </div>
</template>

<script>
import GrantAmount from "@/components/Main/admin/grantPage/GrantAmount";
import Document from "@/components/Main/admin/Document";
import GrantRequest from "@/components/Main/admin/grantPage/GrantRequest";
import GrantConditions from "@/components/Main/admin/grantPage/GrantConditions";
import WinnersBlock from "@/components/Main/admin/grantPage/winners/WinnersBlock";
import request from "@/services/request";

export default {
  name: "grantPage",
  components: {WinnersBlock, GrantConditions, GrantRequest, Document, GrantAmount},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
      allDocuments: [],
    }
  },
  mounted() {
    if (this.accessEdit) {
      this.getAllDocuments()
    }
  },
  computed: {},
  methods: {
    async getAllDocuments() {
      let res = await request({
        endpoint: '/api/documents',
        method: 'get',
      })
      if (res.status === 200) {
        this.allDocuments = res.data
      } else {
        this.snackbarMessage = "Не удалось загрузить документы"
        this.snackbarColor = 'red'
        this.snackbar = true
      }
    },
    //getSelectedDocuments() {
     // console.log(this.documentsToAdd)
      // if (this.documentsToAdd.length) {
      //   this.value.documents = [...this.documentsToAdd]
      // }
    //},
    clearDocumentList() {
      const accept = confirm('Удалить все документы?')
      if (accept) {
        this.value.documents = []
      }
    },
    addDocumentFirst() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: ''
      }
      this.value.documents.unshift(newItem)
    },
    addDocument() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: ''
      }
      this.value.documents.push(newItem)
    },
    deleteDocument(index) {
      this.value.documents.splice(index, 1);
    },
    changeDocPositionPrev(index) {
      if (index > 0) {
        this.value.documents.splice(index - 1, 2, this.value.documents[index], this.value.documents[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeDocPositionNext(index) {
      if (index + 1 !== this.value.documents.length) {
        this.value.documents.splice(index, 2, this.value.documents[index + 1], this.value.documents[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  }
}
</script>

<style lang="scss" scoped>

.help-message {
  background-color: #87CEFA;
  width: 250px;
  color: black;
}

.selected-item {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #dedede;
  border-radius: 20px;
}

</style>