<template>
  <div>
    <img v-if="!edit" :src="value.link" alt="" class="photo" @click="popup = true">
    <div v-if="popup === true" class="photoPopup">
      <img alt="" class="photoPopup__close" src="@/assets/icons/grantRequest__close.svg" @click="popup = false">
      <img :src="value.link" alt="" class="photoPopup__photo">
    </div>
    <div v-if="edit">
      <div>
        <label>Адрес ссылки:</label>
        <input v-model="value.link" style="width: 500px; border: 1px solid grey; margin-left: 20px;">
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="addItemFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addItem(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteItem(value)">Delete</v-button>
      <v-button class="ml-2" @click="changePositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changePositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";

export default {
  name: "Photo",
  components: {VButton},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
      popup: false,
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-photo', item)
    },
    addItem(item) {
      this.$emit('add-photo', item)
    },
    addItemFirst(item) {
      this.$emit('add-photo-first', item)
    },
    changePositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changePositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>