<template>
  <div class="grantAmount">
    <p v-if="!edit" class="grantAmount__text" v-html="value.grantAmountText"></p>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <vue-editor :edit-data-prop.sync="value.grantAmountText"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0; width: 500px; margin: 0 auto">
      <v-button @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "GrantAmount",
  components: {VueEditor, VButton},
  props: {
    value: {
      type: [Object, Number],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
    }
  },
}
</script>

<style scoped>

</style>