<template>
  <div @click="yearSelect(value)">
    <p v-if="!edit" @click="typeFilter">
      {{ value.year }}
    </p>
    <div v-if="edit" class="documents-edit">
      <label>
        <span>Значение года:</span>
        <input class="edit-input" v-model="value.year" style="margin-left: 20px;">
      </label>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="addItemFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addItem(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteItem(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeYearPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeYearPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";

export default {
  name: "Year",
  components: {VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-year', item)
    },
    addItem(item) {
      this.$emit('add-year', item)
    },
    addItemFirst(item) {
      this.$emit('add-year-first', item)
    },
    changeYearPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeYearPositionNext(item) {
      this.$emit('change-next', item)
    },
    typeFilter() {
      this.$emit('year-filter', this.value.year)
    },
    yearSelect(item) {
      this.$emit('year-select', item)
    }
  },
}
</script>

<style lang="scss">
</style>