<template>
  <div class="documents">
    <h1 class="help__message" v-if="accessEdit">Нормативные документы</h1>
    <div class="selects">
      <v-select class="v-select"
                :items="dataSelects.documents"
                dense
                color="#418FCE"
                append-icon="mdi-arrow-down-drop-circle-outline"
                v-model="documentType"
      ></v-select>
      <v-select class="v-select"
                :items="dataSelects.grants"
                dense
                color="#418FCE"
                append-icon="mdi-arrow-down-drop-circle-outline"
                v-model="grantType"
      ></v-select>
      <v-select class="v-select"
                :items="dataSelects.years"
                dense
                color="#418FCE"
                append-icon="mdi-arrow-down-drop-circle-outline"
                v-model="year"
      ></v-select>
    </div>
    <div class="documents__documentTypes">
      <DocumentType
          v-for="(type, i) in value.documentTypes"
          :key="i"
          :access-edit="accessEdit"
          :class="(type.selected === 1) ? 'documents__documentType_selected' : ''"
          :value.sync="type"
          class="documents__documentType"
          @delete-documentType="deleteDocumentType(i)"
          @add-documentType="addDocumentType()"
          @add-documentType-first="addDocumentTypeFirst()"
          @change-prev="changeDocTypePositionPrev(i)"
          @change-next="changeDocTypePositionNext(i)"
          @docType-filter="docTypeFilter"
          @docType-select="docTypeSelect"
      />
    </div>
    <div class="documents__grantTypes">
      <GrantType
          v-for="(type, i) in value.grantTypes"
          :key="i"
          :access-edit="accessEdit"
          :class="(type.selected === 1) ? 'documents__grantType_selected' : ''"
          :value.sync="type"
          class="documents__grantType"
          @delete-grantType="deleteGrantType(i)"
          @add-grantType="addGrantType()"
          @add-grantType-first="addGrantTypeFirst()"
          @change-prev="changeGrantTypePositionPrev(i)"
          @change-next="changeGrantTypePositionNext(i)"
          @grantType-filter="grantTypeFilter"
          @grantType-select="grantTypeSelect"
      />
    </div>
    <div class="documents__years">
      <Year
          v-for="(year, j) in value.years"
          :key="j"
          :access-edit="accessEdit"
          :class="(year.selected === 1) ? 'documents__year_selected' : ''"
          :value.sync="year"
          class="documents__year"
          @delete-year="deleteYear(j)"
          @add-year="addYear()"
          @add-year-first="addYearFirst()"
          @change-prev="changeYearPositionPrev(j)"
          @change-next="changeYearPositionNext(j)"
          @year-filter="yearFilter"
          @year-select="yearSelect"
      />
    </div>
    <div style="border-bottom: 1px solid #B5C7D3; width: 100%"></div>
    <div class="documents__files-no-data" v-if="!filteredDocumentList.length">Документов нет</div>
    <div class="documents__files" v-else>
      <Document
          v-for="(document, i) in filteredDocumentList"
          :key="i"
          :access-edit="accessEdit"
          :documentType="documentType"
          :grantType="grantType"
          :value.sync="document"
          :newDocumentType="true"
          :year="year"
          class="document"
          @delete-document="deleteDocument(i)"
          @add-document="addDocument()"
          @add-document-first="addDocumentFirst()"
          @change-prev="changeDocPositionPrev(i)"
          @change-next="changeDocPositionNext(i)"
      />
    </div>
  </div>
</template>

<script>
import Year from "@/components/Main/admin/Year";
import DocumentType from "@/components/Main/admin/DocumentType";
import GrantType from "@/components/Main/admin/GrantType";
import Document from "@/components/Main/admin/Document";
import request from '@/services/request';

export default {
  name: "documents",
  components: {Document, GrantType, DocumentType, Year},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
      documentType: 'Все документы',
      grantType: 'Все стипендии',
      year: 'Все года',
      documentsList: [],
      dataSelects: {
        documents: [],
        grants: [],
        years: [],
      }
    }
  },
  mounted() {
    this.getDocuments()
  },
  computed: {
    filteredDocumentList() {
      let filteredList = [...this.documentsList]

      if (this.grantType !== 'Все стипендии') {
        filteredList = filteredList.filter(item => item.grantType === this.grantType)
      }
      if (this.documentType !== 'Все документы') {
        filteredList = filteredList.filter(item => item.documentType === this.documentType)
      }
      if (this.year !== 'Все года') {
        filteredList = filteredList.filter(item => Number(item.year) === Number(this.year))
      }
      return filteredList
    }
  },
  methods: {
    async getDocuments() {
      let res = await request({
        endpoint: '/api/documents',
        method: 'get',
      })
      if (res.status === 200) {
        this.documentsList = res.data;
      } else {
        this.snackbarMessage = "Не удалось загрузить документы"
        this.snackbarColor = 'red'
        this.snackbar = true
        //console.log(res.statusText);
      }
      this.documentsList = res.data
      this.value.documentTypes.forEach(element => {
        this.dataSelects.documents.push(element.documentType)
      });
      this.value.grantTypes.forEach(element => {
        this.dataSelects.grants.push(element.grantType)
      });
      this.value.years.forEach(element => {
        this.dataSelects.years.push(element.year)
      });
    },
    addDocumentType() {
      const newItem = {
        id: 0,
        documentType: 'Тип документа',
        selected: '0',
      }
      this.value.documentTypes.push(newItem)
    },
    addDocumentTypeFirst() {
      const newItem = {
        id: 0,
        documentType: 'Тип документа',
        selected: '0',
      }
      this.value.documentTypes.unshift(newItem)
    },
    deleteDocumentType(index) {
      this.value.documentTypes.splice(index, 1)
    },
    changeDocTypePositionPrev(index) {
      if (index > 0) {
        this.value.documentTypes.splice(index - 1, 2, this.value.documentTypes[index], this.value.documentTypes[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeDocTypePositionNext(index) {
      if (index + 1 !== this.value.documentTypes.length) {
        this.value.documentTypes.splice(index, 2, this.value.documentTypes[index + 1], this.value.documentTypes[index])
      }
      // else {
      //   console.log('error');
      // }
    },
    addGrantType() {
      const newItem = {
        id: 0,
        grantType: 'Тип стипендии',
        selected: '0',
      }
      this.value.grantTypes.push(newItem)
    },
    addGrantTypeFirst() {
      const newItem = {
        id: 0,
        grantType: 'Тип стипендии',
        selected: '0',
      }
      this.value.grantTypes.unshift(newItem)
    },
    deleteGrantType(index) {
      this.value.grantTypes.splice(index, 1)
    },
    changeGrantTypePositionPrev(index) {
      if (index > 0) {
        this.value.grantTypes.splice(index - 1, 2, this.value.grantTypes[index], this.value.grantTypes[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeGrantTypePositionNext(index) {
      if (index + 1 !== this.value.grantTypes.length) {
        this.value.grantTypes.splice(index, 2, this.value.grantTypes[index + 1], this.value.grantTypes[index])
      }
      // else {
      //   console.log('error');
      // }
    },
    addYear() {
      const newItem = {
        id: 0,
        year: 'Год',
        selected: '0',
      }
      this.value.years.push(newItem)
    },
    addYearFirst() {
      const newItem = {
        id: 0,
        year: 'Год',
        selected: '0',
      }
      this.value.years.unshift(newItem)
    },
    deleteYear(index) {
      this.value.years.splice(index, 1);
    },
    changeYearPositionPrev(index) {
      if (index > 0) {
        this.value.years.splice(index - 1, 2, this.value.years[index], this.value.years[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeYearPositionNext(index) {
      if (index + 1 !== this.value.years.length) {
        this.value.years.splice(index, 2, this.value.years[index + 1], this.value.years[index])
      }
      // else {
      //   console.log('error');
      // }
    },
    addDocument() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: '',
        year: '',
        grantType: '',
        documentType: ''
      }
      this.value.files.push(newItem)
    },
    addDocumentFirst() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: '',
        year: '',
        grantType: '',
        documentType: ''
      }
      this.value.files.unshift(newItem)
    },
    deleteDocument(index) {
      this.value.files.splice(index, 1);
    },
    changeDocPositionPrev(index) {
      if (index > 0) {
        this.value.files.splice(index - 1, 2, this.value.files[index], this.value.files[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeDocPositionNext(index) {
      if (index + 1 !== this.value.files.length) {
        this.value.files.splice(index, 2, this.value.files[index + 1], this.value.files[index])
      }
      // else {
      //   console.log('error');
      // }
    },
    yearFilter(data) {
      this.year = data;
    },
    docTypeFilter(data) {
      this.documentType = data;
    },
    grantTypeFilter(data) {
      this.grantType = data;
    },
    yearSelect(data) {
      for (let i = 0; i < this.value.years.length; i++) {
        this.value.years[i].selected = 0;
      }
      data.selected = 1;
    },
    grantTypeSelect(data) {
      for (let i = 0; i < this.value.grantTypes.length; i++) {
        this.value.grantTypes[i].selected = 0;
      }
      data.selected = 1;
    },
    docTypeSelect(data) {
      for (let i = 0; i < this.value.documentTypes.length; i++) {
        this.value.documentTypes[i].selected = 0;
      }
      data.selected = 1;
    },
  }
}
</script>

<style lang="scss">
</style>