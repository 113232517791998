<template>
  <div>
    <h1 class="faqSection__header" v-html="value.header"></h1>
    <div v-if="!edit" class="faqSection__div">
      <h1 class="help__message" v-if="accessEdit">Блок FAQ</h1>
      <FaqItem
        v-for="(question, i) in value.questions"
        :key="i"
        :access-edit="accessEdit"
        :value.sync="question"
        @delete-question="deleteQuestion(i)"
        @add-question="addQuestion()"
        @add-question-first="addQuestionFirst()"
        @change-prev="changeQuestionPositionPrev(i)"
        @change-next="changeQuestionPositionNext(i)"
      />
    </div>
    <div v-else-if="edit">
      <div style="margin-top: 10px;">
        <span>Заголовок:</span>
        <input v-model="value.header" style="border: 1px solid grey; margin-left: 20px;"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons"
         style="width: 750px; margin: 0 auto 10px auto; border: 1px solid black">
      <v-button @click="addSectionFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addSection(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteSection(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeSectionPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeSectionPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import FaqItem from "@/components/Main/admin/FaqItem";
import VButton from "@/components/UI/vButton";

export default {
  name: "FaqSection",
  components: {FaqItem, VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    addQuestion() {
      const newItem = {
        id: 0,
        question: 'Вопрос',
        answer: 'Ответ',
      }
      this.value.questions.push(newItem)
    },
    addQuestionFirst() {
      const newItem = {
        id: 0,
        question: 'Вопрос',
        answer: 'Ответ',
      }
      this.value.questions.unshift(newItem)
    },
    deleteQuestion(index) {
      this.value.questions.splice(index, 1)
    },
    changeQuestionPositionPrev(index) {
      if (index > 0) {
        this.value.questions.splice(index - 1, 2, this.value.questions[index], this.value.questions[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeQuestionPositionNext(index) {
      if (index + 1 !== this.value.questions.length) {
        this.value.questions.splice(index, 2, this.value.questions[index + 1], this.value.questions[index])
      }
      // else {
      //   console.log('error');
      // }
    },
    deleteSection(item) {
      this.$emit('delete-section', item)
    },
    addSection(item) {
      this.$emit('add-section', item)
    },
    addSectionFirst(item) {
      this.$emit('add-section-first', item)
    },
    changeSectionPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeSectionPositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>