<template>
  <div>
    <div
        class="card-container"
    >
      <div class="card-left-container">
        <a :href="`${value.npa}`">
          <h3 class="card-left-container__title">{{ value.name }}</h3>
        </a>
        <span class="card-left-container__regionname">{{ value.sprFounderName }}</span>
      </div>
      <div class="card-right-container">
        <span class="card-right-container__title">Кому выплачивается</span>
        <span class="card-right-container__text">{{ value.forWhos }}</span>
        <a class="card-right-container__filename" :href="`${value.npa}`">
          <img alt="pdf" src="@/assets/icons/pdf.svg" class="pdf-img">
          <span class="card-right-container__filename">Нормативно-правовой акт</span>
        </a>
        <span class="payments-title">Размер выплаты</span>

        <div style="display: inline-block">
          <div class="payments-main-container" v-for="(values,index) in nameValues" :key="index">
            <div class="payments-container">
              <span class="payments__title">{{ values.header }}</span>
              <span class="payments__text">{{ values.sum }}</span>
              <span class="payments__title-value">{{ values.period }}</span>
              <span class="payments__title">Количество стипендий: {{ values.count }}</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "grantFoivCards",
  props: {
    value: {
      type: [Object]
    },
  },
  computed: {
    nameValues() {
      return JSON.parse(this.value.values)
    }
  }

}
</script>

<style lang="scss" scoped>
//@import "../../../assets/styles/components/grantNamedRegionsCards";

.grantNamedRegionsCards__edit {

  background: rgba(16, 33, 41, 0.67);
  color: white;

  input {
    margin-top: 10px;
    margin-left: 15px;
    color: white;
    border: 1px solid white;
    padding: 5px 3px;
    width: 500px;
  }
}

.payments-main-container {
  display: inline-block;
  margin-right: 21px;
  margin-bottom: 20px;
}

.payments-main-container:last-child {
  margin-bottom: 0;
}

</style>
