<template>
  <div>
    <div v-if="!edit" class="block">
      <p class="name">{{ value.name }}<span
        class="university">, {{ value.university }}</span>
      </p>
    </div>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <span>ФИО:</span>
        <input v-model="value.name" style="width: 900px; border: 1px solid grey; margin-left: 20px;"/>
      </div>
      <div style="margin-top: 10px;">
        <span>ВУЗ:</span>
        <input v-model="value.university" style="width: 900px; border: 1px solid grey; margin-left: 20px;"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0">
      <v-button @click="addWinnerFirst()">+ первым</v-button>
      <v-button class="ml-2" @click="addWinner()">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteWinner(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeWinnerPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeWinnerPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";

export default {
  name: "Winner",
  components: {VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
    }
  },
  methods: {
    deleteWinner(item) {
      this.$emit('delete-winner', item)
    },
    addWinner(item) {
      this.$emit('add-winner', item)
    },
    addWinnerFirst(item) {
      this.$emit('add-winner-first', item)
    },
    changeWinnerPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeWinnerPositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>