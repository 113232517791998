<template>
  <div v-if="(value.tag === $attrs.type) || ($attrs.type === 'Все новости')" class="newsContainer">
    <div v-if="!edit && !value.bgImage" class="newsItem" @click="popup =!popup">
      <label :class="bgColor(value.tag)" v-html="value.tag"></label>
      <h4 class="newsItem__header" v-html="value.header"></h4>
      <div class="newsItem__published">
        <img alt="Иконка календаря" class="newsItem__pic" src="@/assets/icons/calendar.svg">
        <p class="newsItem__date" v-html="value.date"></p>
      </div>
    </div>
    <div v-if="!edit && value.bgImage" :style="`background-image: url(${value.bgImage})`"
         class="newsItem"
         style="background: rgba(84, 110, 122, 0.72) no-repeat; background-blend-mode: multiply;"
         @click="popup =!popup">
      <label :class="bgColor(value.tag)" v-html="value.tag"></label>
      <h4 class="newsItem__header-bg" v-html="value.header"></h4>
      <div class="newsItem__published-bg">
        <img alt="Иконка календаря" class="newsItem__pic-bg" src="@/assets/icons/calendar-bg.svg">
        <p class="newsItem__date-bg" v-html="value.date"></p>
      </div> 
    </div>
    <div v-if="popup" class="newsPopup">
      <div class="newsPopup__box">
        <img class="newsPopup__close" src="@/assets/icons/popup-close.svg" @click="popup =!popup">
        <label :class="bgColor(value.tag)" class="newsPopup__label" v-html="value.tag"></label>
        <h4 class="newsPopup__header" v-html="value.header"></h4>
        <div class="newsPopup__published-bg">
          <img alt="Иконка календаря" class="newsPopup__pic-bg" src="@/assets/icons/calendar.svg">
          <p class="newsPopup__date-bg" v-html="value.date"></p>
        </div>
        <p class="newsPopup__previewText" v-html="value.previewText" v-if="value.previewText && value.previewText.length"></p>
        <img v-if="value.bgImage" :src="`${value.bgImage}`" alt="Изображение новости" class="newsPopup__img">
        <p class="newsPopup__imgCaption" v-html="value.imageCaption" v-if="value.imageCaption && value.imageCaption.length"></p>
        <p class="newsPopup__text" v-html="value.text" v-if="value.text && value.text.length"></p>
        <div class="newsPopup__documents">
          <Document
              v-for="(document, i) in value.documents"
              :key="i"
              :access-edit="accessEdit"
              :value.sync="document"
              :newDocumentType="true"
              :is-news="true"
              class="document"
              @delete-document="deleteDocument(i)"
              @add-document="addDocument()"
              @add-document-first="addDocumentFirst()"
              @change-prev="changeDocPositionPrev(i)"
              @change-next="changeDocPositionNext(i)"
          />
        </div>
        <p class="newsPopup__finalText" v-html="value.finalText"></p>
      </div>
    </div>
  </div>
</template>

<script>
import Document from "@/components/Main/admin/Document";

export default {
  name: "NewsItem",
  components: {Document},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      popup: false,
      edit: false,
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-news', item)
    },
    addItem(item) {
      this.$emit('add-news', item)
    },
    addItemFirst(item) {
      this.$emit('add-news-first', item)
    },
    changeItemPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeItemPositionNext(item) {
      this.$emit('change-next', item)
    },
    bgColor(item) {
      if (item === 'Образовательным организациям') {
        return 'greenLabelBg'
      }
      if (item === 'ФОИВам') {
        return 'redLabelBg'
      }
      if (item === 'Обучающимся') {
        return 'blueLabelBg'
      }
    },
    addDocumentFirst() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: ''
      }
      this.value.documents.unshift(newItem)
    },
    addDocument() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: ''
      }
      this.value.documents.push(newItem)
    },
    deleteDocument(index) {
      this.value.documents.splice(index, 1);
    },
    changeDocPositionPrev(index) {
      if (index > 0) {
        this.value.documents.splice(index - 1, 2, this.value.documents[index], this.value.documents[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeDocPositionNext(index) {
      if (index + 1 !== this.value.documents.length) {
        this.value.documents.splice(index, 2, this.value.documents[index + 1], this.value.documents[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  },
}
</script>

<style lang="scss">
</style>