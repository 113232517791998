import request from "@/services/request";

export async function getAllPages() {
  let pages = new Array([]);
  let pagesError = null;
  let res = await request({
    endpoint: '/api/page/getAllPages',
    method: 'get'
  })
  if (res.status === 200) pages = res.data;
  else pagesError = res.error;
  return {status: res.status, data: pages, error: pagesError}
}

export async function getAllUniversities() {
  let universities = new Array([]);
  let universitiesError = null;
  let res = await request({
    endpoint: 'https://old.ined.ru/sk_exporter?ajax&id=[%22result%22]&vuz',
    method: 'get',
  })
  if (res.status === 200) universities = JSON.parse(res.data.result);
  else universitiesError = res.error;
  return {status: res.status, data: universities, error: universitiesError}
}

export async function getUniversityContactsById(id) {
  let universityContacts = new Array([]);
  let universitiesError = null;
  let res = await request({
    endpoint: `https://old.ined.ru/sk_exporter?ajax&id=[%22result%22]&vuz=${id}`,
    method: 'get',
  })
  if (res.status === 200) universityContacts = JSON.parse(res.data.result);
  else universitiesError = res.error;
  return {status: res.status, data: universityContacts, error: universitiesError}
}

export async function updatePages(id, data) {
  let updateError = null;
  let res = await request({
    endpoint: id != null ? '/api/page/updatePage' : '/api/page/addPage',
    method: 'post',
    body: data
  })
  if (res.status !== 200) {
    updateError = res.error
  }
  return {status: res.status, error: updateError}
}

export async function askQuestion(data) {
  let updateError = null;
  let res = await request({
    endpoint: '/api/feedback/',
    method: 'post',
    headers: { "Content-Type": "multipart/form-data"},
    body: data
  })
  if (res.status !== 200) {
    updateError = res.error
  }
  return {status: res.status, error: updateError}
}

export async function deletePage(id) {
  let error = null;
  let res = await request({
    endpoint: `/api/page/deletePage?pageID=${id}`,
    method: 'get'
  })
  if (res.status !== 200) {
    error = res.error
  }
  return {status: res.status, error: error}
}

export async function addPagesWidgetData(data) {
  let bufData = {...data};
  let widgetDtoArray = [];
  bufData.widgets.forEach(el => widgetDtoArray.push(
    {
      ...el,
      jsonFormat: JSON.stringify(el.jsonFormat),
      pageWidgetDataID: el.pageWidgetDataID ?? null,
      widgetDataDTO: {
        ...el.widgetDataDTO,
        jsonData: JSON.stringify(el.widgetDataDTO.jsonData),
      }
    }
  ))

  let error = null;
  let obj = new Object({
    pageDTO: {
      pageID: bufData?.pageID,
    },
    widgetDTO: widgetDtoArray
  });
  let res = await request({
    endpoint: '/api/page/widget/data/addPagesWidgetData',
    method: 'post',
    body: obj
  })
  if (res.status !== 200) {
    error = res.error
  }
  return {status: res.status, error: error}
}

export async function deletePageWidgetDataByID(id) {
  let error = null;
  let res = await request({
    endpoint: `/api/page/widget/data/deletePageWidgetDataByID?pageWidgetDataID=${id}`,
    method: 'get'
  })
  if (res.status !== 200) {
    error = res.error
  }
  return {status: res.status, error: error}
}

export async function getPageWidgetByURN(URN) {
  let pageWidgetData = null;
  let pagesError = null;
  let res = await request({
    endpoint: `/api/page/widget/data/getPageWidgetByURN?pageURN=${URN}`,
    method: 'get'
  });
  //console.log('ДАННЫЕ ДЛЯ СТРАНИЦ', res.data)
  if (res.status === 200) {
    pageWidgetData = res.data;
    if (pageWidgetData.widgets.length > 0)
      pageWidgetData.widgets = pageWidgetData.widgets.map(el => {
        return {
          ...el,
          widgetDataDTO: {
            ...el.widgetDataDTO,
            jsonData: JSON.parse(el.widgetDataDTO.jsonData)
          }
        }
      })
  } else
    pagesError = res.error;
  return {status: res.status, data: pageWidgetData, error: pagesError}
}