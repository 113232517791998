<template>
  <div>
    <div class="faqBlock">
      <FaqSection
        v-for="(section, i) in value.faqSections"
        :key="i"
        :access-edit="accessEdit"
        :value.sync="section"
        class="faqSection"
        @delete-section="deleteSection(i)"
        @add-section="addSection()"
        @add-section-first="addSectionFirst()"
        @change-prev="changeSectionPositionPrev(i)"
        @change-next="changeSectionPositionNext(i)"
      />
    </div>
  </div>
</template>

<script>
import FaqSection from "@/components/Main/admin/FaqSection";

export default {
  name: "faqBlock",
  components: {FaqSection},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    addSection() {
      const newItem = {
        id: 0,
        header: 'Заголовок раздела',
        questions: [
          {
            id: 0,
            question: 'Вопрос',
            answer: 'Ответ',
          }
        ]
      }
      this.value.faqSections.push(newItem)
    },
    addSectionFirst() {
      const newItem = {
        id: 0,
        header: 'Заголовок раздела',
        questions: [
          {
            id: 0,
            question: 'Вопрос',
            answer: 'Ответ',
          }
        ]
      }
      this.value.faqSections.unshift(newItem)
    },
    deleteSection(index) {
      this.value.faqSections.splice(index, 1)
    },
    changeSectionPositionPrev(index) {
      if (index > 0) {
        this.value.faqSections.splice(index - 1, 2, this.value.faqSections[index], this.value.faqSections[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeSectionPositionNext(index) {
      if (index + 1 !== this.value.faqSections.length) {
        this.value.faqSections.splice(index, 2, this.value.faqSections[index + 1], this.value.faqSections[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  },
}
</script>

<style lang="scss">
</style>