<template>
  <div @click="docTypeSelect(value)">
    <p v-if="!edit" @click="typeFilter">
      {{ value.documentType }}
    </p>
    <div v-if="edit" class="documents-edit">
      <label>
        <span style="color: grey">Тип документа:</span>
        <input v-model="value.documentType" class="edit-input">
      </label>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="addItemFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addItem(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit = !edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteItem(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeDocTypePositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeDocTypePositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";

export default {
  name: "DocumentType",
  components: {VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-documentType', item)
    },
    addItem(item) {
      this.$emit('add-documentType', item)
    },
    addItemFirst(item) {
      this.$emit('add-documentType-first', item)
    },
    changeDocTypePositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeDocTypePositionNext(item) {
      this.$emit('change-next', item)
    },
    typeFilter() {
      this.$emit('docType-filter', this.value.documentType)
    },
    docTypeSelect(item) {
      this.$emit('docType-select', item)
    }
  },
}
</script>

<style lang="scss">
</style>