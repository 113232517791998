<template>
  <div class="stip-selector__container">
    <h2 class="stip-selector__title">Выберите стипендиальную программу</h2>
    <v-select
        :items="dbOptions"
        v-model="stip_id"
        :loading="loading"
        outlined
        class="stip-selector__select"
    >
    </v-select>
    <v-btn
        outlined
        :loading="loading"
        class="stip-selector__button"
        @click="showCards"
        style="margin-left: 20px"
    >Показать
    </v-btn>
    <div>
      <grant-stips-cards v-for="card in cards" :key="card.id" :value="card"></grant-stips-cards>
    </div>
  </div>
</template>

<script>

import getStips from "@/components/StipsRequest";
import getCardsStips from "@/components/StipCardsRequest";
import grantStipsCards from "@/components/Main/admin/grantStipsCards.vue";

export default {
  name: "StipendialSelector",
  components: {grantStipsCards},
  data() {
    return {
      stip_id: null,
      dbOptions: [],
      cards: [],
      loading: false,
    }
  },
  created() {
    this.getStips()
  },
  methods: {
    showCards() {
      if (this.loading)
        return;
      this.loading = true;
      getCardsStips(this.stip_id)
          .then(data => {
                this.cards = data
              }
          )
          .finally(() => this.loading = false)
    },
    getStips() {
      if (this.loading)
        return;
      this.loading = true;
      getStips()
          .then(data => this.dbOptions = data)
          .catch((e) => console.log("Ошибка получения данных " + e))
          .finally(() => this.loading = false)

    }
  }
}
</script>

<style lang="scss">

.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 41px !important;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px !important;
}

.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
  padding: 0 !important;
}

.v-text-field--outlined .v-label {
  top: 10px !important;
}

.stip-selector {

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #0A5981;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #546E7A;
    margin-bottom: 17px;
  }

  &__select {
    display: inline-flex;
    width: 985px;
    margin-top: 17px;
  }

  &__button {
    display: inline-flex;
    width: 155px;
    height: 31px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #418FCE;
    border-color: #418FCE !important;
    @media screen and (max-width: 450px){
      margin-bottom: 20px;
      margin-right: 0!important;
      margin-left: 0!important;
      }
    }
}



</style>
