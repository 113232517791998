<template>
  <div>
    <div class="university__person">
      <div v-if="!edit">
        <p class="person__name" v-html="value.contactPerson"></p>
        <p class="person__position" v-html="value.position"></p>
        <p class="person__address" v-html="value.address"></p>
        <div style="display:flex; flex-direction: row">
          <p class="person__phoneNumber" v-html="value.phoneNumber"></p>
          <span style="width: 4px; height: 4px; background: #546E7A; border-radius: 50%; align-self: center;"></span>
          <p class="person__email" v-html="value.email"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Person",
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    // deletePerson(item) {
    //   this.$emit('delete-person', item)
    // },
    // addPerson(item) {
    //   this.$emit('add-person', item)
    // },
    // addPersonFirst(item) {
    //   this.$emit('add-person-first', item)
    // },
    // changePersonPositionPrev(item) {
    //   this.$emit('change-prev', item)
    // },
    // changePersonPositionNext(item) {
    //   this.$emit('change-next', item)
    // },
  },
}
</script>

<style scoped>

</style>