<template>
  <div style="max-width: 100%">
    <div class="contactsBlock">
      <delegates
        :access-edit="accessEdit"
        :value.sync="value"
      />
    </div>
  </div>
</template>

<script>
import Delegates from "@/components/Main/admin/contacts/Delegates";

export default {
  name: "contactsPage",
  components: {Delegates},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
}
</script>

<style lang="scss">
</style>