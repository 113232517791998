<template>
  <div>
    <div v-if="!edit" class="main">
      <div class="main__text" v-html="value.subtitle">
      </div>
    </div>
    <div v-else-if="edit">
      <h1 class="help__message">Текст с голубым фоном (для стипендий)</h1>
      <div style="margin-top: 10px;">
        <label>Текст:</label>
        <vue-editor :edit-data-prop.sync="value.subtitle"/>
      </div>
      <v-button class="ml-2" @click="edit =!edit">Cancel</v-button>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "mainInfo",
  components: {
    VButton,
    VueEditor
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
    }
  },
}
</script>

<style scoped>

</style>