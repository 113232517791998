import request from "@/services/request";

async function getStips() {
    try {

        const res = await request({
            endpoint: `/api/nogosstp/getFounders`,
            method: 'get'
        });
        if (res.status === 200) {

            return res.data;
        }
        else {
            throw new Error('Ошибка получения данных')
        }
    } catch (e) {
        console.log("Ошибка получения данных " + e);
    }
}

export default getStips
