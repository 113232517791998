<template>
  <div :style="[edit ? {'border': '1px solid blue'} : {}]" class="grantConditions">
    <div v-if="!edit">
      <p class="grantConditions__header">Кто может претендовать на стипендию?</p>
      <p class="grantConditions__text" v-html="value.text"></p>
      <p class="grantConditions__criteriaText" v-html="value.criteria.text"></p>
      <div class="grantCriteriaBlock">
        <div v-if="value.grantType === 'Именная'" class="grantCriteria__personal">
          <div class="grantCriteria__personal-block1">
            <h3 class="block__header" v-html="value.criteria.firstBlockHeader"></h3>
            <p class="block__text" v-html="value.criteria.firstBlockText"></p>
          </div>
          <div class="grantCriteria__personal-block2">
            <h3 class="block__header" v-html="value.criteria.secondBlockHeader"></h3>
            <p class="block__text" v-html="value.criteria.secondBlockText"></p>
          </div>
        </div>
        <div v-if="value.grantType === 'Президента и Правительства'" class="grantCriteria__government">
          <div class="grantCriteria__government-block1">
            <h3 class="block__header" v-html="value.criteria.firstBlockHeader"></h3>
            <p class="block__text" v-html="value.criteria.firstBlockText"></p>
          </div>
          <div class="grantCriteria__government-block2">
            <h3 class="block__header" v-html="value.criteria.secondBlockHeader"></h3>
            <p class="block__text" v-html="value.criteria.secondBlockText"></p>
          </div>
        </div>
        <div v-if="value.grantType === 'Зарубежная'" class="grantCriteria__foreign">
          <div class="grantCriteria__foreign-block1">
            <h3 class="block__header" v-html="value.criteria.firstBlockHeader"></h3>
            <p class="block__text" v-html="value.criteria.firstBlockText"></p>
          </div>
          <div class="grantCriteria__foreign-block2">
            <h3 class="block__header" v-html="value.criteria.secondBlockHeader"></h3>
            <p class="block__text" v-html="value.criteria.secondBlockText"></p>
          </div>
          <div class="grantCriteria__foreign-block3">
            <h3 class="block__header" v-html="value.criteria.thirdBlockHeader"></h3>
            <p class="block__text" v-html="value.criteria.thirdBlockText"></p>
          </div>
        </div>
      </div>
      <p class="grantConditions__achievementsText" v-html="value.achievements.text"></p>
      <div class="grantAchievementsBlock">
        <GrantAchievement
          v-for="(item, i) in value.achievements.box"
          :key="i"
          :access-edit="accessEdit"
          :value.sync="item"
          v-bind:grantType="value.grantType"
          @delete-achievement="deleteAchievement(i)"
          @add-achievement="addAchievement()"
          @add-achievement-first="addAchievementFirst()"
          @change-prev="changeAchievementPositionPrev(i)"
          @change-next="changeAchievementPositionNext(i)"
        />
      </div>
    </div>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <span>Тип стипендии:</span>
        <select v-model="value.grantType" style="border: 1px solid grey; margin-left: 20px;">
          <option>Именная</option>
          <option>Зарубежная</option>
          <option>Президента и Правительства</option>
        </select>
      </div>
      <div style="margin-top: 10px;">
        <span>Заголовок первого блока критериев:</span>
        <vue-editor :edit-data-prop.sync="value.criteria.firstBlockHeader"/>
      </div>
      <div style="margin-top: 10px;">
        <span>Текст первого блока критериев:</span>
        <vue-editor :edit-data-prop.sync="value.criteria.firstBlockText"/>
      </div>
      <div style="margin-top: 10px;">
        <span>Заголовок второго блока критериев:</span>
        <vue-editor :edit-data-prop.sync="value.criteria.secondBlockHeader"/>
      </div>
      <div style="margin-top: 10px;">
        <span>Текст второго блока критериев:</span>
        <vue-editor :edit-data-prop.sync="value.criteria.secondBlockText"/>
      </div>
      <div v-if="value.grantType === 'Зарубежная'" style="margin-top: 10px;">
        <span>Заголовок третьего блока критериев:</span>
        <vue-editor :edit-data-prop.sync="value.criteria.thirdBlockHeader"/>
      </div>
      <div v-if="value.grantType === 'Зарубежная'" style="margin-top: 10px;">
        <span>Текст третьего блока критериев:</span>
        <vue-editor :edit-data-prop.sync="value.criteria.thirdBlockText"/>
      </div>
      <div style="margin-top: 10px;">
        <span>Подзаголовок:</span>
        <vue-editor :edit-data-prop.sync="value.text"/>
      </div>
      <div style="margin-top: 10px;">
        <span>Критерии отбора:</span>
        <vue-editor :edit-data-prop.sync="value.criteria.text"/>
      </div>
      <div style="margin-top: 10px;">
        <span>Требуемые достижения:</span>
        <vue-editor :edit-data-prop.sync="value.achievements.text"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0; width: 500px; margin: 0 auto">
      <v-button @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";
import GrantAchievement from "@/components/Main/admin/grantPage/GrantAchievement";

export default {
  name: "GrantConditions",
  components: {GrantAchievement, VueEditor, VButton},
  props: {
    value: {
      type: [Object, Number],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
    }
  },
  methods: {
    addAchievementFirst() {
      const newItem = {
        id: 0,
        text: 'Текст'
      }
      this.value.achievements.box.unshift(newItem)
    },
    addAchievement() {
      const newItem = {
        id: 0,
        text: 'Текст'
      }
      this.value.achievements.box.push(newItem)
    },
    deleteAchievement(index) {
      this.value.achievements.box.splice(index, 1);
    },
    changeAchievementPositionPrev(index) {
      if (index > 0) {
        this.value.achievements.box.splice(index - 1, 2, this.value.achievements.box[index], this.value.achievements.box[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeAchievementPositionNext(index) {
      if (index + 1 !== this.value.achievements.box.length) {
        this.value.achievements.box.splice(index, 2, this.value.achievements.box[index + 1], this.value.achievements.box[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  }
}
</script>

<style scoped>

</style>