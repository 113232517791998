import request from "@/services/request";

async function getCardsFoivs(foiv_id) {
    try {
        const res = await request({
            endpoint: `/api/getFoivData?foivId=${foiv_id}`,
            method: 'get'
        });
        if (res.status === 200) {
            return res.data;
        }
        else {
            throw new Error('Ошибка получения данных')
        }
    } catch (e) {
        console.log("Ошибка получения данных " + e);
    }
}

export default getCardsFoivs
