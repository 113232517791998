<template>
  <div>
    <h1 class="help__message" v-if="accessEdit">Страница историй успеха</h1>
    <div class="successStoriesPage">
      <SuccessStory
          v-for="(story, i) in successStories"
          :key="i"
          :access-edit="accessEdit"
          :value.sync="story"
          @delete-story="deleteStory(i)"
          @add-story="addStory()"
          @add-story-first="addStoryFirst()"
          @change-prev="changeStoryPositionPrev(i)"
          @change-next="changeStoryPositionNext(i)"
      />
    </div>
  </div>
</template>

<script>
import SuccessStory from "@/components/Main/admin/SuccessStory";
import request from '@/services/request';

export default {
  name: "successStoriesPage",
  components: {SuccessStory},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      successStories: []
    }
  },
  mounted() {
    this.getStories()
  },
  methods: {
    async getStories() {
      let res = await request({
        endpoint: '/api/success-story',
        method: 'get',
      })
      if (res.status === 200) {
        this.successStories = res.data;
      }
    },
    addStory() {
      const newItem = {
        id: 0,
        imgLink: '',
        videoLink: '',
        name: 'Имя Фамилия',
        photo: '',
        university: 'Название учебного заведения',
        grantLink: '/',
        grantLinkText: 'Название стипендии',
        quote: ''
      }
      this.value.successStories.push(newItem)
    },
    addStoryFirst() {
      const newItem = {
        id: 0,
        imgLink: '',
        videoLink: '',
        name: 'Имя Фамилия',
        photo: '',
        university: 'Название учебного заведения',
        grantLink: '/',
        grantLinkText: 'Название стипендии',
        quote: ''
      }
      this.value.successStories.unshift(newItem)
    },
    deleteStory(index) {
      this.value.successStories.splice(index, 1)
    },
    changeStoryPositionPrev(index) {
      if (index > 0) {
        this.value.successStories.splice(index - 1, 2, this.value.successStories[index], this.value.successStories[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeStoryPositionNext(index) {
      if (index + 1 !== this.value.successStories.length) {
        this.value.successStories.splice(index, 2, this.value.successStories[index + 1], this.value.successStories[index])
        // } else {
        //   console.log('error');
        // }
      }

    },
  },
}
</script>

<style lang="scss">
</style>