<template>
  <div class="government">
    <div
      v-if="!edit"
      :style="`background-image: url(${value.backgroundImage})`"
      class="grantCard"    >
      <a :href="`${value.grantLink}`">
        <h3 class="grantCard__header" v-html="value.headerText"></h3>
        <p v-if="$attrs.grantType === 'personal'" class="grantCard__subject" v-html="value.subjectText"></p>
        <p v-if="$attrs.grantType === 'personal'" class="grantCard__type-personal" v-html="value.type"></p>
        <p v-if="$attrs.grantType === 'foreign'" class="grantCard__type-foreign" v-html="value.type"></p>
        <p v-if="$attrs.grantType === 'government'" class="grantCard__type-government" v-html="value.type"></p>
      </a>
    </div>
    <div
      v-if="edit"
      class="grantCard__edit"
      style="display: flex; flex-direction: column;">
      <label>
        <span>Ссылка на стипендию:</span>
        <input v-model="value.grantLink" class="ml-2">
      </label>
      <label>
        <span>URL картинки</span>
        <input v-model="value.backgroundImage" class="ml-2">
      </label>
      <label>Заголовок стипендии:</label>
      <vue-editor :edit-data-prop.sync="value.headerText" class="ml-2" style="color: black"/>
      <label v-if="$attrs.grantType === 'personal'">
        <span>Предмет:</span>
        <input v-model="value.subjectText" class="ml-2">
      </label>
      <label>
        <span>Для кого:</span>
        <input v-model="value.type" class="ml-2">
      </label>
      <v-button class="ml-2" @click="edit =!edit">Cancel</v-button>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="addCard(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addCard(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteCard(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeCardPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeCardPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "GrantCard",
  components: {VButton, VueEditor},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    },
  },
  data() {
    return {
      edit: false,
    }
  },
  methods: {
    deleteCard() {
      this.$emit('delete-card')
    },
    addCard() {
      this.$emit('add-card')
    },
    addCardFirst() {
      this.$emit('add-card-first')
    },
    changeCardPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeCardPositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>
