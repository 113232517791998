<template>
  <div style="max-width: 100%">
    <div v-if="value.imageType === 'grantImage'"
         :style="`background-image: url(${value.grant[0].bgUrl})`"
         class="grantImage grantImage2"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка стиипендии</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant[0].header"/>
        </div>
        <div>
          <label>Текст:</label>
          <input v-model="value.grant[0].text">
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant[0].bgUrl">
        </div>
        <div>
          <label>Текст ссылки:</label>
          <input v-model="value.grant[0].linkName">
        </div>
        <div>
          <label>Адрес ссылки:</label>
          <input v-model="value.grant[0].link">
        </div>
      </div>
      <a
        :href="`${value.grant[0].link}`"
        class="grantImage__link"
        v-html="value.grant[0].linkName">
      </a>
      <h3
        class="grantImage__header"
        v-html="value.grant[0].header">
      </h3>
      <p
        class="grantImage__text"
        v-html="value.grant[0].text">
      </p>
    </div>
    <div
      v-if="value.imageType === 'grantCatalogImage'"
      :style="`background-image: url(${value.grant[0].bgUrl})`"
      class="grantCatalogImage"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка католога стиипендий</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant[0].header"/>
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant[0].bgUrl">
        </div>
      </div>
      <h2 class="grantCatalogImage__header" v-html="value.grant[0].header"></h2>
    </div>
    <div
      v-if="value.imageType === 'mediaHeaderImage'"
      :style="[edit ? {'height': '500px !important', 'background-image': `url(${value.grant.bgUrl})`} : {'background-image': `url(${value.grant.bgUrl})`}]"
      class="mediaHeaderImage"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка медиа</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant.header"/>
        </div>
        <div>
          <label>Текст:</label>
          <vue-editor :edit-data-prop.sync="value.grant.text"/>
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant.bgUrl">
        </div>
      </div>
      <div v-if="!edit">
        <h2 class="mediaHeaderImage__header" v-html="value.grant.header"></h2>
        <p class="mediaHeaderImage__text" v-html="value.grant.text"></p>
      </div>
    </div>
    <div
      v-if="value.imageType === 'successStoriesHeaderImage'"
      :style="[edit ? {'height': '500px !important', 'background-image': `url(${value.grant.bgUrl})`} : {'background-image': `url(${value.grant.bgUrl})`}]"
      class="successStoriesHeaderImage"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка историй успеха</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant.header"/>
        </div>
        <div>
          <label>Текст:</label>
          <vue-editor :edit-data-prop.sync="value.grant.text"/>
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant.bgUrl">
        </div>
      </div>
      <div v-if="!edit">
        <h2 class="successStoriesHeaderImage__header" v-html="value.grant.header"></h2>
        <p class="successStoriesHeaderImage__text" v-html="value.grant.text"></p>
      </div>
    </div>
    <div
      v-if="value.imageType === 'contactsHeaderImage'"
      :style="[edit ? {'height': '500px !important', 'background-image': `url(${value.grant.bgUrl})`} : {'background-image': `url(${value.grant.bgUrl})`}]"
      class="contactsHeaderImage"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка контактов/статистики</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant.header"/>
        </div>
        <div>
          <label>Текст:</label>
          <vue-editor :edit-data-prop.sync="value.grant.text"/>
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant.bgUrl">
        </div>
      </div>
      <div v-if="!edit">
        <h2 class="contactsHeaderImage__header" v-html="value.grant.header"></h2>
        <p class="contactsHeaderImage__text" v-html="value.grant.text"></p>
      </div>
    </div>
    <div
      v-if="value.imageType === 'documentsHeaderImage'"
      :style="[edit ? {'height': '500px !important', 'background-image': `url(${value.grant.bgUrl})`} : {'background-image': `url(${value.grant.bgUrl})`}]"
      class="documentsHeaderImage"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка документов</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant.header"/>
        </div>
        <div>
          <label>Текст:</label>
          <vue-editor :edit-data-prop.sync="value.grant.text"/>
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant.bgUrl">
        </div>
      </div>
      <div v-if="!edit">
        <h2 class="documentsHeaderImage__header" v-html="value.grant.header"></h2>
        <p class="documentsHeaderImage__text" v-html="value.grant.text"></p>
      </div>
    </div>
    <div
      v-if="value.imageType === 'faqHeaderImage'"
      :style="[edit ? {'height': '500px !important', 'background-image': `url(${value.grant.bgUrl})`} : {'background-image': `url(${value.grant.bgUrl})`}]"
      class="faqHeaderImage"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка FAQ</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant.header"/>
        </div>
        <div>
          <label>Текст:</label>
          <vue-editor :edit-data-prop.sync="value.grant.text"/>
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant.bgUrl">
        </div>
      </div>
      <div v-if="!edit">
        <h2 class="faqHeaderImage__header" v-html="value.grant.header"></h2>
        <p class="faqHeaderImage__text" v-html="value.grant.text"></p>
      </div>
    </div>
    <div
      v-if="value.imageType === 'newsHeaderImage'"
      :style="[edit ? {'height': '500px !important', 'background-image': `url(${value.grant.bgUrl})`} : {'background-image': `url(${value.grant.bgUrl})`}]"
      class="newsHeaderImage"
    >
      <div v-if="edit" class="grantImage__edit-container">
        <div>
          <h1 class="white">шапка новостей</h1>
          <label>Заголовок:</label>
          <vue-editor :edit-data-prop.sync="value.grant.header"/>
        </div>
        <div>
          <label>Текст:</label>
          <vue-editor :edit-data-prop.sync="value.grant.text"/>
        </div>
        <div>
          <label>Ссылка на изображение:</label>
          <input v-model="value.grant.bgUrl">
        </div>
      </div>
      <div v-if="!edit">
        <h2 class="newsHeaderImage__header" v-html="value.grant.header"></h2>
        <p class="newsHeaderImage__text" v-html="value.grant.text"></p>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button class="ml-2" @click="edit =! edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "grantImage",
  components: {VueEditor, VButton},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
    }
  },
}

</script>

<style lang="scss" scoped>
.white{
  background-color: white;
  width: 250px;
  color: black;
}
</style>