<template>
  <div>
    <div v-if="!edit" class="faqItem">
      <p class="faqItem__question" v-html="value.question"></p>
      <p v-if="isOpened" class="faqItem__answer" v-html="value.answer"></p>
      <img v-if="!isOpened" alt=""
           class="faqItem__btn" src="@/assets/icons/faq.svg" @click="isOpened = !isOpened">
      <img v-if="isOpened" alt=""
           class="faqItem__btn" src="@/assets/icons/faqIsOpened.svg" @click="isOpened = !isOpened">
    </div>
    <div v-else-if="edit">
      <div style="margin-top: 10px;">
        <span>Вопрос:</span>
        <vue-editor :edit-data-prop.sync="value.question"/>
      </div>
      <div style="margin-top: 10px;">
        <span>Ответ:</span>
        <vue-editor :edit-data-prop.sync="value.answer"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="margin: 0 auto 20px auto; width: 600px">
      <v-button @click="addQuestionFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addQuestion(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteQuestion(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeQuestionPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeQuestionPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "FaqItem",
  components: {VueEditor, VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      isOpened: false,
    }
  },
  methods: {
    deleteQuestion(item) {
      this.$emit('delete-question', item)
    },
    addQuestion(item) {
      this.$emit('add-question', item)
    },
    addQuestionFirst(item) {
      this.$emit('add-question-first', item)
    },
    changeQuestionPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeQuestionPositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>