<template>
  <div>
    <year
      v-for="(year, i) in value"
      :key="i"
      :access-edit="accessEdit"
      :value.sync="year"
      @delete-year="deleteYear(i)"
      @add-year="addYear()"
      @add-year-first="addYearFirst()"
      @change-prev="changeYearPositionPrev(i)"
      @change-next="changeYearPositionNext(i)"
    />
  </div>
</template>

<script>
import Year from "@/components/Main/admin/grantPage/winners/Year";

export default {
  name: "WinnersBlock",
  components: {Year},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    addYearFirst() {
      const newItem = {
        id: 0,
        year: 'Год конкурса',
        winnerHeader: 'Заголовок',
        winners: [{id: 0, name: 'ФИО', university: ''}],
        documentsHeader: 'Победители конкурса',
        documents: [{id: 0, title: 'Название файла', fileLink: ''}],
      }
      this.value.unshift(newItem)
    },
    addYear() {
      const newItem = {
        id: 0,
        year: 'Год конкурса',
        winnerHeader: 'Заголовок',
        winners: [{id: 0, name: 'ФИО', university: ''}],
        documentsHeader: 'Победители конкурса',
        documents: [{id: 0, title: 'Название файла', fileLink: ''}],
      }
      this.value.push(newItem)
    },
    deleteYear(index) {
      this.value.splice(index, 1);
    },
    changeYearPositionPrev(index) {
      if (index > 0) {
        this.value.splice(index - 1, 2, this.value[index], this.value[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeYearPositionNext(index) {
      if (index + 1 !== this.value.length) {
        this.value.splice(index, 2, this.value[index + 1], this.value[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  }
}
</script>

<style scoped>

</style>