<template>
  <div>
    <div v-if="!edit" class="successStories__item successStories__item-pc">
      <img :src="value.photo" alt="Фотография" class="item__photo">
      <div class="item__container">
        <h3 class="item__name">{{ value.name }}</h3>
        <p class="item__university" v-html="value.university"></p>
        <router-link :to="value.grantLink" class="item__grant">
          <span v-html="value.grantLinkText"></span>
        </router-link>
        <div class="item__quote">
          <img alt="Иконка кавычек" class="item__symbol" src="@/assets/icons/“.svg">
          <p class="item__quote-text" v-html="value.quote"></p>
        </div>
      </div>
    </div>
    <div v-if="!edit" class="successStories__item successStories__item-mobile">
      <div class="table__success-story">
        <img :src="value.photo" alt="Фотография" class="item__photo">
        <div class="item__container">
          <h3 class="item__name">{{ value.name }}</h3>
          <router-link :to="value.grantLink" class="item__grant">
            <span v-html="value.grantLinkText"></span>
          </router-link>
        </div>
      </div>
      <p class="item__university" v-html="value.university"></p>
      <div class="item__quote">
        <img alt="Иконка кавычек" class="item__symbol" src="@/assets/icons/“.svg">
        <p class="item__quote-text" v-html="value.quote"></p>
      </div>
    </div>
<!--    <div v-if="edit" class="card__success-edit">-->
<!--      <label>-->
<!--        <span>Photo человека</span>-->
<!--        <input v-model="value.photo">-->
<!--      </label>-->
<!--      <label>-->
<!--        <span>Имя человека</span>-->
<!--        <input v-model="value.name">-->
<!--      </label>-->
<!--      <label>-->
<!--        <span>Название университета</span>-->
<!--        <input v-model="value.university">-->
<!--      </label>-->
<!--      <label>-->
<!--        <span>Ссылка на страницу</span>-->
<!--        <input v-model="value.url">-->
<!--      </label>-->
<!--      <label>-->
<!--        <span>Название ссылки</span>-->
<!--        <input v-model="value.textUrl">-->
<!--      </label>-->
<!--      <label>-->
<!--        <span>Текст в кавычках</span>-->
<!--        <input v-model="value.quote">-->
<!--      </label>-->
<!--    </div>-->
<!--    <div v-if="accessEdit" class="system-buttons">-->
<!--      <v-button @click="addItemFirst(value)">+ первым</v-button>-->
<!--      <v-button class="ml-2" @click="addItem(value)">+ последним</v-button>-->
<!--      <v-button class="ml-2" @click="edit = !edit">Edit</v-button>-->
<!--      <v-button class="ml-2" @click="deleteItem(value)">delete</v-button>-->
<!--      <v-button class="ml-2" @click="changeItemPositionPrev(value)">&#8592;</v-button>-->
<!--      <v-button class="ml-2" @click="changeItemPositionNext(value)">&#8594;</v-button>-->
<!--    </div>-->
  </div>
</template>

<script>
//import VButton from "@/components/UI/vButton";

export default {
  name: "MainCardSuccessStories",
  components: {
    //VButton
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-doc', item)
    },
    addItem(item) {
      this.$emit('add-doc', item)
    },
    addItemFirst(item) {
      this.$emit('add-doc-first', item)
    },
    changeItemPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeItemPositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>

<script>
import VButton from "@/components/UI/vButton";

export default {
  name: "MainCardSuccessStories",
  components: {VButton},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-doc', item)
    },
    addItem(item) {
      this.$emit('add-doc', item)
    },
    addItemFirst(item) {
      this.$emit('add-doc-first', item)
    },
    changeItemPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeItemPositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>