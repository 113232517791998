<template>
  <div>
    <h1 class="help__message" v-if="accessEdit">новости для главной страницы</h1>
    <button v-if="edit" style="border: 1px solid grey" @click="value.class = !value.class">Главная страница</button>
    <div class="newsBlock">
      <a class="newsBlock__header" href="/news">Новости</a>
      <div v-if="value.class" class="newsBlock__sections">
        <Section
          v-for="(section, i) in value.sections"
          :key="i"
          :access-edit="accessEdit"
          :value.sync="section"
          @delete-section="deleteSection(i)"
          @add-section="addSection()"
          @add-section-first="addSectionFirst()"
          @type-filter="typeFilter"
        />
      </div>
    </div>
    <div class="news">
      <template>
        <NewsItem
          v-for="(item, i) in newsList"
          :key="i"
          :access-edit="accessEdit"
          :type="type"
          :value.sync="item"
          @delete-news="deleteNewsItem(i)"
          @add-news="addNewsItem()"
          @add-news-first="addNewsItemFirst()"
          @change-prev="changeItemPositionPrev(i)"
          @change-next="changeItemPositionNext(i)"
        />
      </template>
    </div>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <label>Количество элементов на странице:</label>
        <input v-model="value.countNews" style="width: 500px; border: 1px solid grey; margin-left: 20px;">
      </div>
      <v-button class="ml-2" @click="edit =!edit">Cancel</v-button>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import NewsItem from "@/components/Main/admin/NewsItem";
import Section from "@/components/Main/admin/Section";
import request from '@/services/request';
import VButton from "@/components/UI/vButton";
import {formatDate} from "@/utils/helpers";

export default {
  name: "news",
  components: {NewsItem, Section, VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      type: 'Все новости',
      newsList: [],
    }
  },
  computed: {
    getNewsCount() {
      return !isNaN(Number.parseInt(this.value.countNews)) ? this.value.countNews : 0 ;
    }
  },
  async mounted() {
    const res = await request({
      endpoint: '/api/news',
      method:'get'
    })
    if (res.status === 200) {
      if (this.getNewsCount > 0){
        res.data.some((news, index) => {
          var obj = {
            imageCaption: news.signature,
            bgImage: news.img,
            documents: news.documents,
            finalText: news.finish,
            header: news.title,
            label: 'Тег',
            previewText: news.preview,
            id: news.id,
            date: formatDate(news.date),
            text: news.text,
            tag: news.tag
          }
          this.newsList.push(obj)
          return index === this.getNewsCount-1;
        });
      } else {
        res.data.forEach(news => {
          var obj = {
            imageCaption: news.signature,
            bgImage: news.img,
            documents: news.documents,
            finalText: news.finish,
            header: news.title,
            label: 'Тег',
            previewText: news.preview,
            id: news.id,
            date: formatDate(news.date),
            text: news.text,
            tag: news.tag
          }
          this.newsList.push(obj)
        });
      }
    }
    // else{
    //   console.log( res.statusText);
    // }
  },
  methods: {
    typeFilter(data) {
      this.type = data;
    }
  },
}
</script>

<style lang="scss">
.help__message{
  background-color: #87CEFA;
  width: 250px;
  color: black;
}
</style>