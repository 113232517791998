<template>
  <div>
    <div
          class="card-container"
      >
        <div class="card-left-container">
          <a :href="`${value.stipAct}`">
          <h3 class="card-left-container__title">{{ value.stipName }}</h3>
          </a>
          <span class="card-left-container__regionname">{{ value.regName }}</span>
        </div>
        <div class="card-right-container">
          <span class="card-right-container__title">Кому выплачивается</span>
          <span class="card-right-container__text">{{ value.komu }}</span>
          <a class="card-right-container__filename" :href="`${value.stipAct}`">
            <img alt="pdf" src="@/assets/icons/pdf.svg" class="pdf-img">
            <span class="card-right-container__filename">Нормативно-правовой акт</span>
          </a>
          <span class="payments-title">Размер выплаты</span>
          <div class="payments-main-container">
            <div class="payments-container" v-if="value.sumStud != null">
              <span class="payments__title">Студенты</span>
              <span class="payments__text">{{ value.sumStud }} ₽</span>
              <span class="payments__title-value">Выплачивается {{ value.period }} раз за период стипендии</span>
              <span class="payments__title">Количество стипендий: {{ value.numStud }}</span>
            </div>
            <div class="payments-container" v-if="value.sumAsp != null">
              <span class="payments__title">Аспиранты</span>
              <span class="payments__text">{{ value.sumAsp }} ₽</span>
              <span class="payments__title-value">Выплачивается {{ value.period }} раз за период стипендии</span>
              <span class="payments__title">Количество стипендий: {{ value.numAsp}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>

export default {
  name: "grantNamedRegionsCards",
  props: {
    value: {
      type: [Object]
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/components/grantNamedRegionsCards";

.grantNamedRegionsCards__edit {

  background: rgba(16, 33, 41, 0.67);
  color: white;

  input {
    margin-top: 10px;
    margin-left: 15px;
    color: white;
    border: 1px solid white;
    padding: 5px 3px;
    width: 500px;
  }
}
</style>
