<template>
  <div style="border-bottom: 1px solid #7492A0; position: relative">
    <h1 class="help__message" v-if="accessEdit">Фото (медиа)</h1>
    <h3 class="photoBlock__header" v-html="value.photoText"></h3>
    <div class="photoBlock">
      <photo
        v-for="(photo, i) in value.photo"
        :key="i"
        :access-edit="accessEdit"
        :value.sync="photo"
        @delete-photo="deletePhoto(i)"
        @add-photo="addPhoto()"
        @add-photo-first="addPhotoFirst()"
        @change-prev="changePhotoPositionPrev(i)"
        @change-next="changePhotoPositionNext(i)"
      />
    </div>
    <div class="photoBlock__dateDiv">
      <img alt="" class="photoBlock__icon" src="@/assets/icons/calendar.svg">
      <p class="photoBlock__date" v-html="value.photoDate"></p>
    </div>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <label>Заголовок:</label>
        <input v-model="value.photoText" style="width: 500px; border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Дата:</label>
        <input v-model="value.photoDate" style="width: 500px; border: 1px solid grey; margin-left: 20px;">
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0">
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import Photo from "@/components/Main/admin/media/Photo";
import VButton from "@/components/UI/vButton"

export default {
  name: "mediaPhoto",
  components: {Photo, VButton},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
    }
  },
  methods: {
    addPhotoFirst() {
      const newItem = {
        id: 0,
        link: ''
      }
      this.value.photo.unshift(newItem)
    },
    addPhoto() {
      const newItem = {
        id: 0,
        link: ''
      }
      this.value.photo.push(newItem)
    },
    deletePhoto(index) {
      this.value.photo.splice(index, 1);
    },
    changePhotoPositionPrev(index) {
      if (index > 0) {
        this.value.photo.splice(index - 1, 2, this.value.photo[index], this.value.photo[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changePhotoPositionNext(index) {
      if (index + 1 !== this.value.photo.length) {
        this.value.photo.splice(index, 2, this.value.photo[index + 1], this.value.photo[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  }
}
</script>

<style scoped>

</style>