<template>
  <div style="max-width: 100%; background: #DAE7EE;">
    <template v-if="!edit">
      <div class="videoBlock">
        <div class="videoBlock__text" v-html="value.data[0].text"></div>
        <video :src="value.data[0].link" class="videoBlock__video" controls="controls"></video>
      </div>
    </template>
    <div v-else-if="edit">
      <h1 class="help__message">блок с видео (главная)</h1>
      <div style="margin-top: 10px;">
        <label>Ссылка видео:</label>
        <input v-model="value.data[0].link" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Текст:</label>
        <vue-editor :edit-data-prop.sync="value.data[0].text"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VueEditor from "@/components/UI/vue-ckeditor";
import VButton from "@/components/UI/vButton";

export default {
  name: "videoBlock",
  components: {VButton, VueEditor},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false
    }
  },
}
</script>

<style scoped>

</style>