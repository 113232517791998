<template>
  <div>
    <div class="year" v-if="!edit" @click="popup = true">
      <img src="@/assets/icons/winner-icon.svg" alt="" class="year__icon">
      <p class="year__text" v-html="value.year"></p>
    </div>
    <div v-if="popup" class="popup">
      <img class="popup__close" src="@/assets/icons/grantRequest__close.svg" @click="popup = false" alt="">
      <p v-html="value.winnerHeader" class="winnerHeader"></p>
      <div class="documents__files" style="margin-bottom: 8px; border-bottom: 1px solid #B5C7D3;">
        <p class="documents__header" v-html="value.documentsHeader"></p>
        <div v-if="edit">
          <div style="margin-top: 10px;">
            <span>Заголовок:</span>
            <vue-editor :edit-data-prop.sync="value.winnerHeader"/>
          </div>
          <div style="margin-top: 10px;">
            <span>Победители:</span>
            <vue-editor :edit-data-prop.sync="value.documentsHeader"/>
          </div>
        </div>
        <div v-if="accessEdit" class="system-buttons" style="padding: 0">
          <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
        </div>
        <v-btn v-if="accessEdit" @click="clearDocumentList" color="primary" width="200" style="margin: 15px auto">Удалить все документы</v-btn>
        <v-select
            v-if="accessEdit"
            return-object
            multiple
            v-model="value.documents"
            item-text="title"
            item-value="title"
            :items="allDocuments"
            label="Выберите документ"
        >
          <template slot="selection" slot-scope="{ item }">
            <span v-html="item.title" class="selected-item"></span>
          </template>
          <template slot="option" slot-scope="{ item }">
            <span v-html="item.title"></span>
          </template>
        </v-select>
        <Document
          v-for="(document, i) in value.documents"
          :key="i"
          :access-edit="accessEdit"
          :value.sync="document"
          class="document"
          @delete-document="deleteDocument(i)"
          @add-document="addDocument()"
          @add-document-first="addDocumentFirst()"
          @change-prev="changeDocPositionPrev(i)"
          @change-next="changeDocPositionNext(i)"
        />
      </div>
      <div style="margin-top: 29px">
        <winner
          v-for="(winner, i) in value.winners"
          :key="i"
          :access-edit="accessEdit"
          :value.sync="winner"
          @delete-winner="deleteWinner(i)"
          @add-winner="addWinner()"
          @add-winner-first="addWinnerFirst()"
          @change-prev="changeWinnerPositionPrev(i)"
          @change-next="changeWinnerPositionNext(i)"
        />
      </div>
    </div>
    <div v-if="edit">
      <div style="margin-top: 10px;">
        <span>Год:</span>
        <vue-editor :edit-data-prop.sync="value.year"/>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0">
      <v-button @click="addYearFirst()">+ первым</v-button>
      <v-button class="ml-2" @click="addYear()">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteYear(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeYearPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeYearPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";
import Winner from "@/components/Main/admin/grantPage/winners/Winner";
import Document from "@/components/Main/admin/Document";
import request from "@/services/request";

export default {
  name: "Year",
  components: {Winner, VueEditor, VButton, Document},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      edit: false,
      popup: false,
      allDocuments: [],
    }
  },
  mounted() {
    if (this.accessEdit) {
      this.getAllDocuments()
    }
  },
  methods: {
    async getAllDocuments() {
      let res = await request({
        endpoint: '/api/documents',
        method: 'get',
      })
      if (res.status === 200) {
        this.allDocuments = res.data
      } else {
        this.snackbarMessage = "Не удалось загрузить документы"
        this.snackbarColor = 'red'
        this.snackbar = true
      }
    },
    deleteYear(item) {
      this.$emit('delete-year', item)
    },
    addYear(item) {
      this.$emit('add-year', item)
    },
    addYearFirst(item) {
      this.$emit('add-year-first', item)
    },
    changeYearPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeYearPositionNext(item) {
      this.$emit('change-next', item)
    },
    addWinnerFirst() {
      const newItem = {
        id: 0,
        name: 'ФИО',
        university: ''
      }
      this.value.winners.unshift(newItem)
    },
    addWinner() {
      const newItem = {
        id: 0,
        name: 'ФИО',
        university: ''
      }
      this.value.winners.push(newItem)
    },
    deleteWinner(index) {
      this.value.winners.splice(index, 1);
    },
    changeWinnerPositionPrev(index) {
      if (index > 0) {
        this.value.winners.splice(index - 1, 2, this.value.winners[index], this.value.winners[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeWinnerPositionNext(index) {
      if (index + 1 !== this.value.winners.length) {
        this.value.winners.splice(index, 2, this.value.winners[index + 1], this.value.winners[index])
      }
        // else {
      //   console.log('error');
      // }
    },
    clearDocumentList() {
      const accept = confirm('Удалить все документы?')
      if (accept) {
        this.value.documents = []
      }
    },
    addDocumentFirst() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: ''
      }
      this.value.documents.unshift(newItem)
    },
    addDocument() {
      const newItem = {
        id: 0,
        title: 'Название файла',
        fileLink: ''
      }
      this.value.documents.push(newItem)
    },
    deleteDocument(index) {
      this.value.documents.splice(index, 1);
    },
    changeDocPositionPrev(index) {
      if (index > 0) {
        this.value.documents.splice(index - 1, 2, this.value.documents[index], this.value.documents[index - 1])
      }
      // else {
      //   console.log('error');
      // }
    },
    changeDocPositionNext(index) {
      if (index + 1 !== this.value.documents.length) {
        this.value.documents.splice(index, 2, this.value.documents[index + 1], this.value.documents[index])
      }
      // else {
      //   console.log('error');
      // }
    },
  },
}
</script>

<style scoped>

.selected-item {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #dedede;
  border-radius: 20px;
}

</style>