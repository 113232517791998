<template>
  <div class="successStory__dividers">
    <div v-if="!edit" class="successStory successStory-mobile">
      <div class="successStory__container">
        <div class="successStory__info">
          <div class="success__header">
            <div><img :src="value.photo" alt="" class="successStory__photo"></div>
            <div>
            <p class="successStory__name" v-html="value.name"></p>
            <router-link :to="value.grantLink" style="display: flex; margin-right: auto; margin-top: 10px;">
              <p class="successStory__link" v-html="value.grantLinkText"></p>
            </router-link>
            </div>
          </div>
          <div class="success__footer">
            <p class="successStory__university" v-html="value.university"></p>
            <div class="successStory__quote">
              <img alt="Иконка кавычек" class="successStory__symbol" src="@/assets/icons/“.svg">
              <p class="successStory__quoteText" v-html="value.quote"></p>
              </div>
            <p class="successStory__fullText" @click="popup = true">Читать дальше</p>
            </div>
        </div>
      </div>
    </div>
    <div v-if="!edit" class="successStory">
      <div class="successStory__container">
        <img :src="value.photo" alt="" class="successStory__photo">
        <div class="successStory__info">
          <p class="successStory__name" v-html="value.name"></p>
          <p class="successStory__university" v-html="value.university"></p>
          <router-link :to="value.grantLink" style="display: flex; margin-right: auto; margin-top: 10px;">
            <p class="successStory__link" v-html="value.grantLinkText"></p>
          </router-link>
          <div class="successStory__quote">
            <img alt="Иконка кавычек" class="successStory__symbol" src="@/assets/icons/“.svg">
            <p class="successStory__quoteText" v-html="value.quote"></p>
          </div>
          <p class="successStory__fullText" @click="popup = true">Читать дальше</p>
        </div>
      </div>
    </div>
    <div v-else-if="edit">
      <div style="margin-top: 10px;">
        <label>Ссылка видео:</label>
        <input v-model="value.videoLink" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Ссылка изображения:</label>
        <input v-model="value.imgLink" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Имя:</label>
        <input v-model="value.name" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Фото:</label>
        <input v-model="value.photo" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Учебное заведение:</label>
        <input v-model="value.university" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Ссылка на стипендию:</label>
        <input v-model="value.grantLink" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px;">
        <label>Название стипендии:</label>
        <input v-model="value.grantLinkText" style="border: 1px solid grey; margin-left: 20px;">
      </div>
      <div style="margin-top: 10px; width: 500px">
        <label>Текст:</label>
        <vue-editor :edit-data-prop.sync="value.quote"/>
      </div>
    </div>
    <div v-if="popup" class="storyPopup">
      <div class="storyPopup__box">
        <img class="storyPopup__close" src="@/assets/icons/popup-close.svg" @click="popup = false">
        <h4 class="storyPopup__header">История успеха</h4>
        <div class="successStory__container">
          <div style="display: flex; flex-direction: row">
            <img :src="value.photo" alt="" class="successStory__photo">
            <div class="successStory__info">
              <p class="successStory__name" v-html="value.name"></p>
              <router-link :to="value.grantLink" style="display: flex">
                <p class="successStory__link" v-html="value.grantLinkText"></p>
              </router-link>
              <p class="successStory__university" v-html="value.university"></p>
            </div>
          </div>
          <div class="successStory__quote">
            <img alt="Иконка кавычек" class="successStory__symbol" src="@/assets/icons/“.svg">
            <p class="successStory__quoteText" v-html="value.quote"></p>
          </div>
        </div>
        <iframe v-if="value.videoLink" :src="value.videoLink" class="successStory__video"></iframe>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="margin: 0 auto 20px auto; width: 560px">
      <v-button @click="addStoryFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addStory(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteStory(value)">Delete</v-button>
      <v-button class="ml-2" @click="changeStoryPositionPrev(value)">&#8592;</v-button>
      <v-button class="ml-2" @click="changeStoryPositionNext(value)">&#8594;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "SuccessStory",
  components: {VueEditor, VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      popup: false,
    }
  },
  methods: {
    deleteStory(item) {
      this.$emit('delete-story', item)
    },
    addStory(item) {
      this.$emit('add-story', item)
    },
    addStoryFirst(item) {
      this.$emit('add-story-first', item)
    },
    changeStoryPositionPrev(item) {
      this.$emit('change-prev', item)
    },
    changeStoryPositionNext(item) {
      this.$emit('change-next', item)
    },
  },
}
</script>

<style scoped>

</style>