<template>

  <div style="max-width: 100%; background: #E0F2EE;">
    <div class="askQuestionBlock" v-if="!sended">
      <h1 class="help__message" v-if="accessEdit">Блок задать вопрос</h1>
      <form v-if="!edit" @submit.prevent="askQuestion">
        <h3 class="askQuestionBlock__header">Задать вопрос</h3>
        <p class="askQuestionBlock__subheader" v-html="value.subheader"></p>
        <div class="askQuestion-section">
          <img alt="" class="askQuestion-section__img" src="@/assets/icons/select-arrow.svg">
          <label class="askQuestion-section__label">Раздел, к которому относится ваш вопрос:</label>
          <select v-model="question.type" class="askQuestion-section__select" required>
            <option>Стипендия Президента Российской Федерации</option>
            <option>Стипендия Правительства Российской Федерации</option>
            <option>Стипендия Президента Российской Федерации по приоритетным направлениям</option>
            <option>Стипендия Правительства Российской Федерации по приоритетным направлениям</option>
            <option>Стипендия Правительства Российской Федерации для студентов (СПО)</option>
            <option>Стипендия Президента Российской Федерации для обучения за рубежом</option>
            <option>Именные стипендии</option>
            <option>Иное</option>
          </select>
        </div>
        <div class="askQuestion-row" >
          <div class="askQuestion-info" style="margin-right: 24px;">
            <label class="askQuestion-info__label">Представьтесь, пожалуйста:</label>
            <input v-model="question.fio" class="askQuestion-info__input" required type="text">
          </div>
          <div class="askQuestion-info">
            <label class="askQuestion-info__label">Ваша электронная почта:</label>
            <input v-model="question.email" class="askQuestion-info__input" required type="email">
          </div>
        </div>
        <div class="askQuestion-question">
          <label class="askQuestion-question__label">Ваш вопрос:</label>
          <textarea v-model="question.message" class="askQuestion-question__input" required></textarea>
        </div>
        <v-file-input
          label="Выбрать файл..."
          outlined
          dense
          clearable
          v-model="question.file"
          :accept="['application/pdf', 'image/jpeg', 'image/png']"
          :error="error"
          :error-messages="errorMessage"
          :rules="rules"
          @change="errUpd($event)" 
          id="field__file"
          class="input__file" 
          background-color="#FFFF" 
    ></v-file-input>
        <button class="askQuestionBlock-btn" type="submit">Отправить</button>
      </form>
      <div v-if="edit">
        <div style="margin-top: 10px;">
          <label>Позаголовок:</label>
          <vue-editor :edit-data-prop.sync="value.subheader"/>
        </div>
      </div>
    </div>
    <div v-else-if="sended" class="sanded-img">
      <div class="askQuestionBlock pb-4">
        <h3 class="askQuestionBlock__header">Задать вопрос</h3>
        <p class="askQuestionBlock__subheader pb-0" style="border-bottom: 0" v-html="value.subheader"></p>
      </div>
      <img class="big-img" alt="" src="@/assets/images/1648054797_23-kartinkin-net-p-kartinki-voprosa-24 1.png">
      <img class="small-img" alt="" src="@/assets/images/photo_2022-08-11_14-51-50.jpg">
      <div class="askQuestionBlock pt-0 pb-4">
        <button class="askQuestionBlock-btn" @click="statusSended">Назад</button>
      </div>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button class="ml-2" @click="edit =! edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";
import {askQuestion} from "@/API/Admin";

export default {
  name: "askQuestion",
  components: {VueEditor, VButton},
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      error: false,
      sended: false,
      errorMessage: '',
      rules: [
        value => value != null ||  'Выберите файл',
        value => value?.size < 150000000 || 'Размер файла не должен превышать 150 МБ!',
      ],
      question: {
        fio: '',
        email: '',
        message: '',
        type: '',
        file: null,
      }
    }
  },
  // if ((this.question.fio !== '') && (this.question.message !== '')
  // && (this.question.type !== '') && (this.question.email.includes('@'))
  // && (this.question.email.includes('.')))
  methods: {
    errUpd(evt) {
      if (evt && evt.size < 150000000) {
        this.error = false;
        this.errorMessage = '';
      }
    },
    statusSended(){
      this.sended = !this.sended
    },
    async askQuestion() {
      const fd = new FormData();
      fd.append("fio", this.question.fio);
      fd.append("email", this.question.email);
      fd.append("message", this.question.message);
      fd.append("type", this.question.type);
      fd.append("file", document.querySelector("#field__file").files[0]); 
      let res = await askQuestion(fd)
      if (res.status === 200) {
        this.statusSended();
        this.question.type = '';
        this.question.fio = '';
        this.question.email = '';
        this.question.message = '';
        document.querySelector('.field__file').value='';
        //console.log('Успех');
      }
      // else {
      //   console.log('Неудача')
      // }
    },
  }
}
</script>

<style scoped>

</style>