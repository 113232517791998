<template>
  <div>
    <p v-if="!edit" class="newsBlock__section" @click="typeFilter()">
      {{ value.section }}
    </p>
    <div v-if="edit" class="section__link-edit">
      <label>
        <span>Значение года:</span>
        <input v-model="value.section" class="edit-input">
      </label>
      <label>
        <span>Выделение:</span>
        <input v-model="value.selected" class="edit-input">
      </label>
    </div>
    <div v-if="accessEdit" class="system-buttons">
      <v-button @click="addItemFirst(value)">+ первым</v-button>
      <v-button class="ml-2" @click="addItem(value)">+ последним</v-button>
      <v-button class="ml-2" @click="edit =!edit">Edit</v-button>
      <v-button class="ml-2" @click="deleteItem(value)">delete</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";

export default {
  name: "Section",
  components: {VButton},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
    }
  },
  methods: {
    deleteItem(item) {
      this.$emit('delete-section', item)
    },
    addItemFirst(item) {
      this.$emit('add-section-first', item)
    },
    addItem(item) {
      this.$emit('add-section', item)
    },
    typeFilter() {
      this.$emit('type-filter', this.value.section)
    }
  },
}
</script>

<style lang="scss">
.section__link-edit {
  display: flex;
  flex-direction: column;
}

.edit-input {
  border: solid silver 1px;
}
</style>
