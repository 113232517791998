<template>
  <div>
    <div class="grantRequest">
      <div v-if="!edit">
        <p class="grantRequest__header">Как подать конкурсную заявку на стипендию?</p>
        <p class="grantRequest__text" v-html="value.text"></p>
        <p class="carousel__btn" @click="popup = true">Подробнее</p>
      </div>
      <div v-if="edit">
        <div style="margin-top: 10px;">
          <span class="newsItem__label">Основной текст:</span>
          <vue-editor :edit-data-prop.sync="value.text"/>
        </div>
        <div style="margin-top: 10px;">
          <span class="newsItem__label">Заголовок:</span>
          <vue-editor :edit-data-prop.sync="value.popupHeader"/>
        </div>
        <div style="margin-top: 10px;">
          <span class="newsItem__label">Подзаголовок:</span>
          <vue-editor :edit-data-prop.sync="value.popupSubHeader"/>
        </div>
        <div style="margin-top: 10px;">
          <span class="newsItem__label">Текст попапа:</span>
          <vue-editor :edit-data-prop.sync="value.popupText"/>
        </div>
      </div>
    </div>
    <div v-if="!edit && popup" class="grantRequest__popup">
      <img alt="" class="popup__close" src="@/assets/icons/grantRequest__close.svg" @click="popup = false">
      <p class="popup__header" v-html="value.popupHeader"></p>
      <p class="popup__subHeader" v-html="value.popupSubHeader"></p>
      <div class="popup__text" v-html="value.popupText"></div>
    </div>
    <div v-if="accessEdit" class="system-buttons" style="padding: 0">
      <v-button @click="edit =!edit">Edit</v-button>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/UI/vButton";
import VueEditor from "@/components/UI/vue-ckeditor";

export default {
  name: "GrantRequest",
  components: {VueEditor, VButton},
  props: {
    value: {
      type: [Object, Number],
      default: null
    },
    accessEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      popup: false,
    }
  },
}
</script>

<style scoped>

</style>