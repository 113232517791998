<template>
  <div class="foiv-selector__container">
    <h2 class="foiv-selector__title">Выберите ФОИВ или ООВО</h2>
      <v-select
          :items="dbOptions"
          v-model="foiv_id"
          :loading="loading"
          outlined
          class="foiv-selector__select"
      >
      </v-select>
      <v-btn
          outlined
          :loading="loading"
          class="foiv-selector__button"
          @click="showCards"
          style="margin-left: 20px"
      >Показать
      </v-btn>
    <span class="foivName">{{ foivName }}</span>
    <div>
      <grant-foiv-cards
          v-for="card in cards"
          :key="card.id"
          :value="card"
      >
      </grant-foiv-cards>
    </div>
  </div>
</template>

<script>
import grantFoivCards from "@/components/Main/admin/grantFoivCards";
import getFoivs from "@/components/FoivRequest";
import getCardsFoivs from "@/components/FoivCardsRequest";

export default {
  name: "FoivSelector",
  components: {grantFoivCards},
  data() {
    return {
      foiv_id: null,
      dbOptions: [],
      cards: [],
      loading: false,
    }
  },
  created() {
    this.getFoivs()
  },
  computed: {
    foivName() {
      return this.dbOptions.find(item => item.value === this.foiv_id)?.text;
    }
  },
  methods: {
    showCards() {
      if (this.loading)
        return;
      this.loading = true;
      getCardsFoivs(this.foiv_id)
          .then(data => {
                this.cards = data
              }
          )

          .finally(() => this.loading = false)
    },
    getFoivs() {
      if (this.loading)
        return;
      this.loading = true;
      getFoivs()
          .then(data => this.dbOptions = data.map(item => ({value: item.id, text: item.name})))
          .catch((e) => console.log("Ошибка получения данных " + e))
          .finally(() => this.loading = false)

    }
  }
}
</script>

<style lang="scss">

.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 41px !important;
}

.v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px !important;
}

.v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
  padding: 0 !important;
}

.v-text-field--outlined .v-label {
  top: 10px !important;
}

.foiv-selector {

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #0A5981;
    margin-bottom: 20px;
    margin-top: 25px;
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #546E7A;
    margin-bottom: 17px;
  }

  &__select {
    display: inline-flex;
    width: 985px;
    margin-top: 17px;
  }

  &__button {
    display: inline-flex;
    width: 155px;
    height: 31px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #418FCE;
    border-color: #418FCE !important;
    @media screen and (max-width: 450px) {
      margin-right: 0!important;
      margin-left: 0!important;
    }
  }
}

.foivName {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #0A5981;
  margin-bottom: 20px;

  @media screen and (max-width: 450px) {
    display: block;
    margin-top: 20px;
  }
}

</style>
