<template>
  <div>
    <div class="delegates__university">
      <h3 v-if="!edit" class="university__header" @click="popup = true" v-html="value['short_name']"></h3>
      <div v-if="popup" class="university__popup">
        <img class="newsPopup__close" src="@/assets/icons/popup-close.svg" @click="popup =!popup">
        <p class="university__name" v-html="value['short_name']"></p>
        <div class="university__line"></div>
        <person
          v-for="(person, i) in contacts"
          :key="i"
          :access-edit="accessEdit"
          :value.sync="person"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Person from "@/components/Main/admin/contacts/Person";
import {getUniversityContactsById} from "@/API/Admin";

export default {
  name: "University",
  components: {Person},
  props: {
    value: {
      type: [Object, Array],
    },
    accessEdit: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      edit: false,
      popup: false,
      contacts: [],
    }
  },
  methods: {
    async getContacts() {
      let res = await getUniversityContactsById(this.value.id);
      this.contacts = res.data;
      // if (res.status === 200) {
      //   console.log('Успех');
      // } else {
      //   console.log('Неудача')
      // }
    },
  },
  mounted() {
    this.getContacts();
  }
}
</script>

<style scoped>

</style>